import styled from 'styled-components';
import Button from '@components/atoms/buttons/Button';
import { withTheme } from '@material-ui/core';

export const LoginContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoginFormContainer = withTheme(styled('div')`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 0;
  }
`);

export const LoginTextFields = withTheme(styled('div')`
  width: 100%;
  margin-bottom: 28px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 42px;
  }
`);

export const LoginTopBar = withTheme(styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  margin-bottom: 48px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 32px;
    flex-direction: column;
    align-items: flex-start;
  }
`);

export const LoginSteps = withTheme(styled('div')`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 60px;
  }
`);

export const LoginStep = withTheme(styled('span')`
  display: inline-block;
  width: 24px;
  height: 4px;
  margin-left: 8px;
  background-color: ${(props) => (props.isActive
    ? `${({ theme }) => theme.palette.primary.main}`
    : `${({ theme }) => theme.palette.grey['400']}`)};
`);

export const LoginBody = styled.div`
  flex: 1;
  position: relative;
`;

export const LoginFooter = withTheme(styled('div')`
  margin-top: 16px;
  padding-bottom: 48px;
  font-size: 16px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-bottom: 85px;
    margin-top: 0;
  }
`);

export const StyledButton = withTheme(styled(Button)`
  && {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('lg')} {
      bottom: 20px;
      right: auto;
      left: 0;
      width: auto;
      min-width: 196px;
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`);
