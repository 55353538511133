import bookingHeaderTranslations from '@components/organisms/BookingHeader/translations/en.json';
import yourBookingsTranslations from '@components/organisms/YourBookings/translations/en.json';
import confirmReservationHeaderTranslations
  from '@components/organisms/ConfirmReservationHeader/translations/en.json';
import resultDialogTranslations from '@components/organisms/ResultDialog/translations/en.json';
import loginHeaderTranslations from '@components/organisms/LoginHeader/translations/en.json';

const organismsEnTranslations = {
  bookingHeader: bookingHeaderTranslations,
  yourBookings: yourBookingsTranslations,
  confirmReservationHeader: confirmReservationHeaderTranslations,
  resultDialog: resultDialogTranslations,
  loginHeader: loginHeaderTranslations,
};

export default organismsEnTranslations;
