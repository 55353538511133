import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@components/atoms/icons/NavigateBeforeIcon';
import { observer } from 'mobx-react';

const NavigateBeforeButton = (props) => (
  <IconButton {...props}>
    <NavigateBeforeIcon />
  </IconButton>
);

export default observer(NavigateBeforeButton);
