import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const StyledIconContainer = withTheme(styled('div')`
  font-size: 56px;
  text-align: center;
  padding: 72px 0 8px;
  color: ${({ theme }) => theme.palette.success.main}
`);

export const StyledInformationText = withTheme(styled('div')`
  color: ${({ theme }) => theme.palette.grey['700']}
`);

export const StyledNotificationIconContainer = withTheme(styled('div')`
  font-size: 56px;
  text-align: center;
  padding: 72px 0 8px;
  color: ${({ theme }) => theme.palette.primary.main}
`);

export const StyledUnsuccessfulIconContainer = withTheme(styled('div')`
  font-size: 56px;
  text-align: center;
  padding: 72px 0 8px;
  color: ${({ theme }) => theme.palette.error.main}
`);
