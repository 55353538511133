import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TextField from '@components/atoms/TextField';
import Box from '@components/atoms/Box';
import Typography from '@components/atoms/Typography';
import Form from '@components/organisms/Form';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Div100vh from 'react-div-100vh';
import { withTranslation, Trans } from 'react-i18next';
import {
  ResetPasswordBody,
  ResetPasswordContainer,
  ResetPasswordStep,
  ResetPasswordSteps,
  ResetPasswordTextFields,
  ResetPasswordTopBar,
  StyledButton
} from './styles';
import { NEW_PASSWORD_URL } from '@helpers/urls';

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      authorizing: false,
      accountName: '',
      logo: '',
      primaryColor: '',
      showPasswordResetConfirmationInfo: false
    };

    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleResetPassword(fieldsValues) {
    if (fieldsValues.email.value.trim().length === 0) return;

    this.props.userStore.resetPassword({
      email: fieldsValues.email.value,
      redirectUrl: window.location.origin + NEW_PASSWORD_URL
    }).then(() => this.setState({ showPasswordResetConfirmationInfo: true }));
  }

  componentDidMount() {
    this.props.accountStore.fetchAccount();
  }

  renderResetPasswordForm() {
    const { t } = this.props;

    return (
      <Form errors={this.props.userStore.error}>
        <ResetPasswordTextFields>
          <TextField
            name='TextField'
            key='email'
            label={t('resetPasswordScreen:emailFieldLabel')}
            margin='normal'
            fullWidth
          />
        </ResetPasswordTextFields>
        <StyledButton
          data-cy='ResetPasswordButton'
          name='Button'
          loading={this.props.userStore.signingIn}
          fullWidth
          variant='contained'
          onClick={this.handleResetPassword}
          key='accept'
        >
          <Trans>resetPasswordScreen:resetPassword</Trans>
          <ArrowForward/>
        </StyledButton>
      </Form>
    );
  }

  renderResetPasswordBody() {
    return (
      <ResetPasswordBody>
        <Grid item xs={12}>
          <ResetPasswordTopBar>
            <img
              src={this.props.accountStore.accountLogo}
              alt={this.props.accountStore.accountName}
              width='48px'
              height='47px'
            />
            <ResetPasswordSteps>
              <ResetPasswordStep isActive/>
              <ResetPasswordStep/>
            </ResetPasswordSteps>
          </ResetPasswordTopBar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <h1><Trans>resetPasswordScreen:title</Trans></h1>
          <ResetPasswordContainer>
            {this.renderResetPasswordForm()}
          </ResetPasswordContainer>
        </Grid>
      </ResetPasswordBody>
    );
  }

  renderConfirmationInfo() {
    return (
      <ResetPasswordBody>
        <Grid item xs={12}>
          <ResetPasswordTopBar>
            <img
              src={this.props.accountStore.accountLogo}
              alt={this.props.accountStore.accountName}
              width='48px'
              height='47px'
            />
            <ResetPasswordSteps>
              <ResetPasswordStep isActive/>
              <ResetPasswordStep/>
            </ResetPasswordSteps>
          </ResetPasswordTopBar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <h1><Trans>resetPasswordScreen:confirmationTitle</Trans></h1>
        </Grid>
      </ResetPasswordBody>
    );
  }

  render() {
    return (
      <>
        <CssBaseline/>
        <Div100vh>
          <ResetPasswordContainer>
            {this.state.showPasswordResetConfirmationInfo
              ? this.renderConfirmationInfo()
              : this.renderResetPasswordBody()}
            <Box py={2}>
              <Typography variant='body1' data-cy='infiniteLoopC'>© 2020 Infinite Loop</Typography>
            </Box>
          </ResetPasswordContainer>
        </Div100vh>
      </>
    );
  }
}

const resetPasswordScreenWithoutI18n = inject('userStore', 'accountStore')(observer(ResetPasswordScreen));
export { resetPasswordScreenWithoutI18n };
export default withTranslation()(resetPasswordScreenWithoutI18n);
