import api from '@/api';

const auth = {
  signIn: (user) => api.post('auth/sign_in', user),
  resetPassword: (params) => api.post('auth/password', { ...params }),
  newPassword: (params) => api.put('auth/password', { ...params }),

  validateToken: () => api.get('auth/validate_token')
};

const timeSlots = {
  forDate: (dateString, divisionId) => api.get(
    `time_slots?&date=${dateString}&divisionId=${divisionId}`
  )
};

const bookings = {
  add: (timeSlotId, divisionId, spotsAmount) => api.post('bookings', {
    booking: {
      timeSlotId,
      divisionId,
      spotsAmount
    }
  }),

  delete: (bookingId) => api.delete(`bookings/${bookingId}`),

  all: (dateString) => api.get(`bookings?date=${dateString}`)
};

const userDivisions = { get: (params) => api.get('/user_divisions', { params }) };

const notifications = { add: (timeSlotId) => api.post('booking_notifications', { time_slot_id: timeSlotId }) };

const account = { getAccount: () => api.get('accounts') };

const colorTheme = { get: (id) => api.get(`/color_themes/${id}`) };

export {
  auth,
  timeSlots,
  bookings,
  userDivisions,
  notifications,
  account,
  colorTheme
};
