import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  CREATED,
  CREATING,
  DELETED,
  DELETING,
  UNAUTHORIZED,
  ERROR,
  FETCHED,
  FETCHING,
  NO_RESPONSE_FROM_SERVER,
  UPDATED,
  UPDATING,
} from '@/helpers/constants';
import {
  CLEAR_CREATING_STATUSES,
  CLEAR_DELETING_STATUSES,
  CLEAR_FETCHING_STATUSES,
  CLEAR_UPDATING_STATUSES,
  CREATING_ERROR,
  DELETING_ERROR,
  FETCHING_ERROR,
  UPDATING_ERROR
} from './consts.jsx';

class ApiStore {
  fetched;
  fetching;
  deleting;
  deleted;
  updating;
  updated;
  creating;
  created;
  errorStatus;
  error;

  constructor(errorsStore) {
    this.errorsStore = errorsStore;
    this.getDataFromResponse = this.getDataFromResponse.bind(this);
    this.getIncludedFromResponse = this.getIncludedFromResponse.bind(this);
    makeObservable(this, {
      error: observable,
      errorStatus: observable,
      fetched: observable,
      fetching: observable,
      created: observable,
      creating: observable,
      updated: observable,
      updating: observable,
      deleted: observable,
      deleting: observable,
      changedState: action.bound,
      clearError: action.bound,
      setError: action.bound,
      passErrorToErrorStore: action.bound,
      handleServerError: action.bound,
      setUpdating: action.bound,
      setUpdated: action.bound,
      setCreated: action.bound,
      setCreating: action.bound,
      setDeleted: action.bound,
      setDeleting: action.bound,
      setFetched: action.bound,
      setFetching: action.bound,
      setErrorStatus: action.bound
    });
    this.initFields();
  }

  initFields() {
    runInAction(() => {
      this.fetched = false;
      this.fetching = false;
      this.deleting = false;
      this.deleted = false;
      this.updating = false;
      this.updated = false;
      this.creating = false;
      this.created = false;
      this.errorStatus = false;
      this.error = {};
    });
  }

  setError(error) {
    this.errorStatus = true;
    this.error = error;
  }

  clearError() {
    this.errorStatus = false;
    this.error = {};
  }

  handleServerError(error) {
    if (error.response) {
      this.passErrorToErrorStore(`${error.response.statusText} ${error.response.status}`);
    } else {
      this.passErrorToErrorStore(NO_RESPONSE_FROM_SERVER);
    }
  }

  passErrorToErrorStore(errorMessage) {
    this.errorsStore.setError(errorMessage);
  }

  changedState(state, error = '') {
    switch (state) {
      case FETCHING:
        this.setFetching(true);
        this.setFetched(false);
        this.clearError();
        break;
      case FETCHED:
        this.setFetched(true);
        this.setFetching(false);
        break;
      case FETCHING_ERROR:
        this.setFetching(false);
        this.setFetched(false);
        this.setError(error);
        break;
      case CLEAR_FETCHING_STATUSES:
        this.setFetching(false);
        this.setFetched(false);
        break;
      case DELETING:
        this.setDeleting(true);
        this.setDeleted(false);
        break;
      case DELETED:
        this.setDeleting(false);
        this.setDeleted(true);
        break;
      case DELETING_ERROR:
        this.setDeleting(false);
        this.setDeleted(false);
        this.setError(error);
        break;
      case CLEAR_DELETING_STATUSES:
        this.setDeleting(false);
        this.setDeleted(false);
        break;
      case UPDATING:
        this.setUpdating(true);
        this.setUpdated(false);
        break;
      case UPDATED:
        this.setUpdating(false);
        this.setUpdated(true);
        break;
      case UPDATING_ERROR:
        this.setUpdating(false);
        this.setUpdated(false);
        this.setError(error);
        break;
      case CLEAR_UPDATING_STATUSES:
        this.setUpdating(false);
        this.setUpdated(false);
        break;
      case CREATING:
        this.setCreating(true);
        this.setCreated(false);
        break;
      case UNAUTHORIZED:
        this.setCreating(false);
        this.setError(error);
        break;
      case CREATED:
        this.setCreating(false);
        this.setCreated(true);
        break;
      case CREATING_ERROR:
        this.setCreating(false);
        this.setCreated(false);
        this.setError(error);
        break;
      case CLEAR_CREATING_STATUSES:
        this.setCreating(false);
        this.setCreated(false);
        break;
      default:
        break;
    }
  }

  setFetched(newFetched) {
    this.fetched = newFetched;
  }

  setFetching(newFetching) {
    this.fetching = newFetching;
  }

  setErrorStatus(newErrorStatus) {
    this.errorStatus = newErrorStatus;
  }

  setUpdating(newUpdating) {
    this.updating = newUpdating;
  }

  setUpdated(newUpdated) {
    this.updated = newUpdated;
  }

  setCreated(newCreated) {
    this.created = newCreated;
  }

  setCreating(newCreating) {
    this.creating = newCreating;
  }

  setDeleted(newDeleted) {
    this.deleted = newDeleted;
  }

  setDeleting(newDeleting) {
    this.deleting = newDeleting;
  }

  clearApiStatuses() {
    this.changeState(CLEAR_CREATING_STATUSES);
    this.changeState(CLEAR_DELETING_STATUSES);
    this.changeState(CLEAR_FETCHING_STATUSES);
    this.changeState(CLEAR_UPDATING_STATUSES);
  }

  getDataFromResponse(response) {
    return response.data.data;
  }

  getIncludedFromResponse(response) {
    return response.data.included;
  }

  getErrorsListFromCaughtError(error) {
    return error.response.data.errors;
  }

  getErrorStatus(error) {
    return error.response.status;
  }
}

export default ApiStore;
