import styled from 'styled-components';
import { variant } from 'styled-system';
import { rgba } from 'polished';

const AlertBodyBase = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 0;
  letter-spacing: 0.01em;
  font-size: 16px;
  line-height: 22px;

  & > div {
    display: flex;
    opacity: 0.9;
    font-size: 22px;
    margin-right: 12px;
  }
`;

export const AlertBody = styled(AlertBodyBase)(
  variant({
    prop: 'severity',
    variants: {
      error: {
        color: '#FD6A6A',
        bg: '#FFF2F2',
      },
      warning: {
        color: 'rgb(102, 60, 0)',
        bg: 'rgb(255, 244, 229)'
      },
      info: {
        color: `${rgba('#676767', 1)}`,
        bg: `${rgba('#ededed', 0.8)}`
      },
      success: {
        color: '#ffffff',
        bg: `${rgba('#4BBF82', 0.8)}`
      }
    }
  })
);
