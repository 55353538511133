import { makeAutoObservable } from 'mobx';
import { formatDate } from '@helpers/formatting/formatting';

class CurrentlyChosenDateStore {
  constructor() {
    this.currentlyChosenDate = new Date();
    makeAutoObservable(this);
  }

  get dateString() {
    return formatDate(this.currentlyChosenDate, true);
  }

  setDate(newDate) {
    this.currentlyChosenDate = newDate;
  }
}

export default CurrentlyChosenDateStore;
