import styled from 'styled-components';

export const Legend = styled.div`
  margin-top: 8px;
`;

export const LegendElement = styled.div`
  display: flex;
  margin: 12px 0;
`;
export const LegendSymbol = styled.div`
  width: 48px;
  height: 24px;
  background-color: ${({ color }) => color};
`;

export const LegendBody = styled.div`
  padding-left: 16px;
`;
