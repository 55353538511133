import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const StyledButton = withTheme(styled('div')`
  padding-bottom: 30px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey['500']};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    width: 50%;
    padding: 5px 2px;
    line-height: 17px;
    font-size: 12px;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.primary['main']};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: 30px;
    text-align: right;
    position: static;
    left: 0;
    bottom: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    button {
      width: auto;
      min-width: 196px;
      margin-left: 16px;
      padding: 5px 15px;
      font-size: 14px;
    }
  }
`);
