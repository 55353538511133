import { userDivisions } from '@/helpers/endpoints';
import { FETCHED, FETCHING, FETCHING_ERROR } from '@helpers/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import ApiStore from './ApiStore';

class UserDivisionStore extends ApiStore {
  constructor(errorsStore) {
    super(errorsStore);
    this.initFields();
    makeObservable(this, {
      userDivisions: observable,
      fetchUserDivisionStore: action.bound
    });
  }

  initFields() {
    runInAction(() => {
      this.userDivisions = [];
    });
  }

  fetchUserDivisionStore({ userId, q = {} } = {}) {
    this.changedState(FETCHING);
    q.kept = true;
    return userDivisions.get({ userId, q })
      .then((response) => runInAction(() => {
        this.userDivisions = (response.data.data).map((record) => record.attributes);
        this.changedState(FETCHED);
      }));
  }
}

export default UserDivisionStore;
