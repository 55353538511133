import { makeObservable, action, observable } from 'mobx';

class BookingDialogStore {
  constructor() {
    this.data = {};
    this.opened = false;
    this.onAgreeClick = () => '';
    makeObservable(this, {
      data: observable,
      onAgreeClick: observable,
      opened: observable,
      open: action.bound,
      setMethodAndData: action.bound,
      close: action.bound,
    });
  }

  setMethodAndData(onAgreeClick, data) {
    this.onAgreeClick = onAgreeClick;
    this.data = data || {};
  }

  open() {
    this.close();
    this.opened = true;
  }

  close() {
    this.opened = false;
  }
}

export default BookingDialogStore;
