import api from '@api';

export default function setup({ userStore }) {
  function assignToken({ headers }) {
    if (headers['access-token']) {
      userStore.assignToken(headers);
    }
  }

  api.interceptors.request.use((config) => {
    config.headers.locale = navigator.language.split('-')[0];
    const { token } = userStore;
    if (!token) return config;

    config.headers['access-token'] = token.accessToken;
    config.headers.client = token.client;
    config.headers.uid = token.uid;

    return config;
  });

  api.interceptors.response.use((response) => {
    assignToken(response);

    return response;
  }, (error) => {
    assignToken(error.response);

    return Promise.reject(error);
  });
}
