import { makeAutoObservable } from 'mobx';

class ResultDialogStore {
  constructor() {
    this.data = {};
    this.opened = false;
    this.variant = '';
    makeAutoObservable(this);
  }

  setVariantAndData(variant, data) {
    this.variant = variant;
    this.data = data || {};
  }

  open() {
    this.close();
    this.opened = true;
  }

  close() {
    this.opened = false;
  }
}

export default ResultDialogStore;
