import React from 'react';
import AnonymousTemplate from './components/templates/AnonymousTemplate';
import { AUTHORIZED_ROUTE, NOT_AUTHORIZED_ROUTE } from './helpers/constants';
import { BOOKING_URL, CONFIRM_CODE_URL, LOGIN_URL, NEW_PASSWORD_URL, RESET_PASSWORD_URL } from './helpers/urls';
import ConfirmCodeScreen from './components/screens/ConfirmCodeScreen';
import LoginScreen from './components/screens/LoginScreen';
import BookingScreen from './components/screens/BookingScreen';
import ResetPasswordScreen from './components/screens/ResetPasswordScreen';
import NewPasswordScreen from './components/screens/NewPasswordScreen';

const routesDefinitions = [
  {
    children: <AnonymousTemplate children={<LoginScreen/>}/>,
    path: LOGIN_URL,
    exactPath: true,
    kind: NOT_AUTHORIZED_ROUTE
  },
  {
    children: <AnonymousTemplate children={<ResetPasswordScreen/>}/>,
    path: RESET_PASSWORD_URL,
    exactPath: true,
    kind: NOT_AUTHORIZED_ROUTE
  },
  {
    children: <AnonymousTemplate children={<NewPasswordScreen/>}/>,
    path: NEW_PASSWORD_URL,
    exactPath: true,
    kind: NOT_AUTHORIZED_ROUTE
  },
  {
    children: <AnonymousTemplate children={<ConfirmCodeScreen/>}/>,
    path: CONFIRM_CODE_URL,
    exactPath: true,
    kind: NOT_AUTHORIZED_ROUTE
  },
  {
    children: <AnonymousTemplate children={<BookingScreen/>}/>,
    path: BOOKING_URL,
    exactPath: true,
    kind: AUTHORIZED_ROUTE
  }
];

export default routesDefinitions;
