import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slot from '@components/atoms/Slot';
import Separator from '@components/atoms/Separator';
import { getMinutesBetweenHours } from '@helpers/time/time';
import { observer } from 'mobx-react';

class Slots extends Component {
  constructor(props) {
    super(props);
    this.pushSeparator = this.pushSeparator.bind(this);
    this.pushSlot = this.pushSlot.bind(this);
    this.getObjectHeight = this.getObjectHeight.bind(this);
    this.objectsToRender = [];
    this.key = 0;
  }

  getObjectHeight(startHour, startMinutes, endHour, endMinutes) {
    return getMinutesBetweenHours(startHour, startMinutes, endHour, endMinutes)
      * this.props.minuteToPixels;
  }

  pushSeparator(height) {
    if (height === 0) return;
    this.key++;
    this.objectsToRender.push(<Separator key={this.key} height={height} widthPercent={90} />);
  }

  pushSlot(slot, height) {
    if (height > 0) {
      this.key++;
      this.objectsToRender.push(
        <Slot
          bookingsAvailable={this.props.bookingsAvailable}
          onClick={this.props.onClick}
          key={this.key}
          {...slot}
          height={height}
        />
      );
    }
  }

  render() {
    this.objectsToRender = [];
    const {
      slots,
      timelineStartHour,
      timelineStartMinutes
    } = this.props;
    const slotsLength = slots.length;

    if (slotsLength === 0) return this.objectsToRender;

    const firstSlot = slots[0];
    const firstSeparatorHeight = this.getObjectHeight(
      timelineStartHour,
      timelineStartMinutes,
      firstSlot.startHour,
      firstSlot.startMinutes
    );
    this.pushSeparator(firstSeparatorHeight);

    for (let i = 0; i < slotsLength - 1; i++) {
      const startingSlot = slots[i];
      const endingSlot = slots[i + 1];

      if (startingSlot === undefined || endingSlot === undefined) break;
      const startingSlotHeight = this.getObjectHeight(
        startingSlot.startHour,
        startingSlot.startMinutes,
        startingSlot.endHour,
        startingSlot.endMinutes
      );
      const separatorHeight = this.getObjectHeight(
        startingSlot.endHour,
        startingSlot.endMinutes,
        endingSlot.startHour,
        endingSlot.startMinutes
      );
      this.pushSlot(startingSlot, startingSlotHeight);
      this.pushSeparator(separatorHeight);
    }

    if (slotsLength >= 1) {
      const lastSlot = slots[slotsLength - 1];
      const lastSlotHeight = this.getObjectHeight(
        lastSlot.startHour,
        lastSlot.startMinutes,
        lastSlot.endHour,
        lastSlot.endMinutes
      );
      this.pushSlot(slots[slotsLength - 1], lastSlotHeight);
    }

    return this.objectsToRender;
  }
}

Slots.propTypes = {
  slots: PropTypes.array.isRequired,
  minuteToPixels: PropTypes.number.isRequired,
  bookingsAvailable: PropTypes.bool.isRequired,
  timelineStartHour: PropTypes.number.isRequired,
  timelineStartMinutes: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default observer(Slots);
