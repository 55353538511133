import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@components/atoms/Box';
import Typography from '@components/atoms/Typography';
import DateSwitcher from '@components/organisms/DateSwitcher';
import {
  addDays,
  getCurrentDateNoTime,
  isValidDateString,
  stringToDate
} from '@helpers/dates/dates';
import BookingHeader from '@components/organisms/BookingHeader';
import YourBookings from '@components/organisms/YourBookings';
import SlotsAndTimeline from '@components/organisms/SlotsAndTimeline';
import { getUrlParam } from '@helpers/urlParams';
import { BOOKING_URL } from '@helpers/urls';
import { formatDate } from '@helpers/formatting/formatting';
import { navigateTo } from '@helpers/navigation/navigation';
import { inject, observer } from 'mobx-react';
import Loader from '@components/atoms/Loader';
import ResultDialog from '@components/organisms/ResultDialog';
import { INITIAL_DIALOG_VARIANTS, RESULT_DIALOG_VARIANTS, SUCCESS, UNAUTHORIZED } from '@helpers/constants';
import { Trans } from 'react-i18next';
import MakeBookingDialog from '@components/organisms/MakeBookingDialog';
import CancelBookingDialog from '@components/organisms/CancelBookingDialog';
import DivisionSelector from '@components/molecules/DivisionSelector';
import { YourBookingsSidebar } from './styles';

class BookingScreen extends Component {
  constructor(props) {
    super(props);
    this.HOW_MANY_PIXELS_IS_ONE_MINUTE = 2.4;
    this.TIMELINE_START_MINUTES = 0;
    this.HOUR_TEXT_LINE_HEIGHT = 24;
    this.startHour = 0;
    this.endHour = 24;

    this.getDateStringFromStore = this.getDateStringFromStore.bind(this);
    this.getDateFromStore = this.getDateFromStore.bind(this);
    this.handleMakeBooking = this.handleMakeBooking.bind(this);
    this.handleBookingCancel = this.handleBookingCancel.bind(this);
    this.renderHeaderSection = this.renderHeaderSection.bind(this);
    this.renderSlotsAndTimelineSection = this.renderSlotsAndTimelineSection.bind(this);
    this.renderYourBookings = this.renderYourBookings.bind(this);
    this.handleUrlParams = this.handleUrlParams.bind(this);
    this.getDateFromUrl = this.getDateFromUrl.bind(this);
    this.getDateStringFromUrl = this.getDateStringFromUrl.bind(this);
    this.validateUrlDate = this.validateUrlDate.bind(this);
    this.handleNotifyMe = this.handleNotifyMe.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.openMakeBookingDialog = this.openMakeBookingDialog.bind(this);
    this.openCancelBookingDialog = this.openCancelBookingDialog.bind(this);
    this.fetchBookingsAndTimeSlots = this.fetchBookingsAndTimeSlots.bind(this);
    this.bookingsAvailable = this.bookingsAvailable.bind(this);
    this.visibleDate = this.visibleDate.bind(this);

    this.validateUrlDate();
    this.fetchDivisions();
  }

  setOpeningAndClosingHours() {
    const openingTime = new Date(this.props.accountStore.currentDivision.openingHour);
    const closingTime = new Date(this.props.accountStore.currentDivision.closingHour);
    this.startHour = openingTime.getHours();
    this.endHour = closingTime.getHours();

    if (this.startHour === this.endHour) this.endHour += 23;
  }

  fetchBookingsAndTimeSlots() {
    this.props.timeSlotsStore.fetchTimeSlots(this.getDateStringFromStore(), this.props.accountStore.currentDivisionId);
    this.props.bookingsStore.fetchBookingsForDate(this.getDateStringFromStore(), this.props.accountStore.currentDivisionId);
  }

  async fetchAndSetColorTheme(colorThemeId) {
    const { colorThemeStore } = this.props;
    await colorThemeStore.fetch(colorThemeId);
  }

  validateUrlDate() {
    if (!isValidDateString(this.getDateStringFromUrl())
      || this.getDateFromUrl() < new Date()
      || this.getDateFromUrl() > this.visibleDate()) {
      this.props.currentlyChosenDateStore.setDate(new Date());
    } else {
      this.props.currentlyChosenDateStore.setDate(this.getDateFromUrl());
    }
  }

  fetchDivisions() {
    const { accountStore, userDivisionStore } = this.props;
    userDivisionStore.fetchUserDivisionStore({ userId: this.props.userStore.id }).then(() => {
      accountStore.assignDivision(accountStore.divisions.find((obj) => obj.id === userDivisionStore.userDivisions[0]));
      accountStore.fetchAccount()
        .then(() => {
          this.fetchAndSetColorTheme(accountStore.colorThemeId);
          this.handleUrlParams();
          this.fetchBookingsAndTimeSlots();
          this.setOpeningAndClosingHours();
        });
    });
  }

  validatedUrlDivision() {
    const urlBookingParam = this.props.accountStore.divisions.find(
      (division) => division.slug === this.getDivisionFromUrl()
    );
    return urlBookingParam || this.props.accountStore.divisions[0];
  }

  getDivisionFromUrl() {
    return getUrlParam('division');
  }

  bookingsAvailable() {
    return this.props.bookingsStore.bookings.length
      < this.props.accountStore.currentDivision.userBookingsPerDay;
  }

  handleUrlParams() {
    this.props.accountStore.assignDivision(this.validatedUrlDivision());
    navigateTo([BOOKING_URL], {
      date: this.getDateStringFromStore(),
      division: this.getCurrentDivisionSlug()
    });
  }

  getDateFromUrl() {
    return stringToDate(this.getDateStringFromUrl());
  }

  getDateStringFromUrl() {
    return getUrlParam('date');
  }

  getDateStringFromStore() {
    return this.props.currentlyChosenDateStore.dateString;
  }

  getDateFromStore() {
    return this.props.currentlyChosenDateStore.currentlyChosenDate;
  }

  getCurrentDivisionSlug() {
    return this.props.accountStore.currentDivisionSlug;
  }

  visibleDate() {
    return addDays(getCurrentDateNoTime(), this.props.accountStore.futureVisibleDays);
  }

  handleDateChange(daysToAdd) {
    const newDate = addDays(this.getDateFromStore(), daysToAdd);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (newDate <= currentDate || newDate > this.visibleDate()) return;
    const newDateString = formatDate(newDate, true);
    navigateTo([BOOKING_URL],
      {
        date: newDateString,
        division: this.getCurrentDivisionSlug()
      });
    this.props.currentlyChosenDateStore.setDate(newDate);
    this.fetchBookingsAndTimeSlots();
  }

  openResultDialog(variant, data) {
    this.props.resultDialogStore.setVariantAndData(variant, data);
    this.props.resultDialogStore.open();
  }

  openMakeBookingDialog(variant, timeSlotData) {
    switch (variant) {
      case INITIAL_DIALOG_VARIANTS.NOTIFY_ME:
        this.props.makeBookingDialogStore.setMethodAndData(
          () => this.handleNotifyMe(timeSlotData.id), timeSlotData
        );
        this.props.makeBookingDialogStore.open();
        break;
      case INITIAL_DIALOG_VARIANTS.MAKE_BOOKING:
        this.props.makeBookingDialogStore.setMethodAndData(
          () => this.handleMakeBooking(timeSlotData.id), timeSlotData
        );
        this.props.makeBookingDialogStore.open();
        break;
    }
  }

  openCancelBookingDialog(bookingData) {
    const bookingDateString = formatDate(bookingData.date, true);
    this.props.cancelBookingDialogStore.setMethodAndData(
      () => this.handleBookingCancel(bookingData.id, bookingDateString),
      bookingData
    );
    this.props.cancelBookingDialogStore.open();
  }

  handleMakeBooking(timeSlotId) {
    this.props.bookingsStore.addBooking(timeSlotId)
      .then(({ status, data }) => {
        if (status === SUCCESS) {
          this.props.makeBookingDialogStore.close();
          this.openResultDialog(RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_SUCCESSFUL, data);
        } else if (status === UNAUTHORIZED) {
          this.props.makeBookingDialogStore.close();
          this.openResultDialog(RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_UNAUTHORIZED);
        } else {
          this.props.makeBookingDialogStore.close();
          this.openResultDialog(RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_UNSUCCESSFUL);
        }
        this.fetchBookingsAndTimeSlots();
      });
  }

  handleNotifyMe(timeSlotId) {
    this.props.notificationsStore.addNotification(timeSlotId)
      .then(() => {
        this.props.makeBookingDialogStore.close();
        this.openResultDialog(
          RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.NOTIFY_ME_SUCCESSFUL
        );
      })
      .catch(() => () => '');
  }

  handleBookingCancel(bookingId) {
    this.props.bookingsStore.cancelBooking(bookingId)
      .then(({
        status,
        data
      }) => {
        this.props.cancelBookingDialogStore.close();
        this.openResultDialog(
          RESULT_DIALOG_VARIANTS.CANCELING_RESULT.substeps.CANCELING_SUCCESSFUL,
          data
        );
        this.fetchBookingsAndTimeSlots();
      });
  }

  renderHeaderSection() {
    return (
      <>
        <BookingHeader userName={this.props.userStore.firstName} />
        {this.renderDivisions()}
        <DateSwitcher
          date={this.getDateFromStore()}
          onBeforeClick={() => this.handleDateChange(-1)}
          onNextClick={() => this.handleDateChange(1)}
        />
      </>
    );
  }

  renderDivisions() {
    if (this.props.accountStore.divisions.length > 1) {
      return (
        <DivisionSelector
          reloadTimeSlots={this.fetchBookingsAndTimeSlots}
          currentDate={this.getDateStringFromStore}
          userDivisions={this.props.userDivisionStore.userDivisions}
        />
      );
    }
  }

  renderSlotsAndTimelineSection() {
    const { bookingsStore, accountStore, timeSlotsStore } = this.props;

    return (
      <SlotsAndTimeline
        userBookings={bookingsStore.bookings}
        bookingsAvailable={this.bookingsAvailable()}
        allowBookingSlotsBlock={accountStore.currentDivision.allowBookingSlotsBlock}
        onSlotClick={this.openMakeBookingDialog}
        hourLineHeight={this.HOUR_TEXT_LINE_HEIGHT}
        timelineStartHour={accountStore.startHour}
        timelineStartMinutes={this.TIMELINE_START_MINUTES}
        minuteToPixels={this.HOW_MANY_PIXELS_IS_ONE_MINUTE}
        slots={timeSlotsStore.timeSlots}
        bookedTimeSlotIds={bookingsStore.timeSlotIds}
        slotsLoading={!bookingsStore.fetched || !timeSlotsStore.fetched || !accountStore.fetched}
        timelineEndHour={accountStore.endHour}
        divisionBookableDays={accountStore.bookableDaysInAdvance}
      />
    );
  }

  renderYourBookings() {
    if (this.props.bookingsStore.fetched) {
      return (
        <YourBookingsSidebar>
          <Typography variant='h4' gutterBottom data-cy='bookingsTitle'>
            <Trans i18nKey='bookingScreen:bookingsTitle'>
              bookingScreen:bookingsTitle
              {' '}
              {{ accountName: this.props.accountStore.accountName }}
            </Trans>
          </Typography>
          <YourBookings
            onCancelBookingClick={this.openCancelBookingDialog}
            bookings={this.props.bookingsStore.bookings}
          />
        </YourBookingsSidebar>
      );
    }
    return <Loader />;
  }

  renderDialogs() {
    return (
      <>
        <CancelBookingDialog />
        <MakeBookingDialog />
        <ResultDialog />
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderDialogs()}
        <Grid container>
          <Grid item xs={12} lg={7}>
            {this.renderHeaderSection()}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={7}>
            {this.renderSlotsAndTimelineSection()}
          </Grid>
          <Grid item xs={12} lg={5}>
            {this.renderYourBookings()}
          </Grid>
        </Grid>
        <Box pt={4} pb={2}>
          <Typography variant='body1' data-cy='infiniteLoopC'>
            © 2020 Infinite
            Loop
          </Typography>
        </Box>
      </>
    );
  }
}

export default inject(
  'currentlyChosenDateStore',
  'notificationsStore',
  'bookingsStore',
  'timeSlotsStore',
  'resultDialogStore',
  'makeBookingDialogStore',
  'accountStore',
  'userDivisionStore',
  'cancelBookingDialogStore',
  'userStore',
  'colorThemeStore'
)(observer(BookingScreen));
