import React from 'react';
import PropTypes from 'prop-types';
import DateRangeIcon from '@components/atoms/icons/DateRangeIcon';
import Typography from '@components/atoms/Typography';
import Box from '@components/atoms/Box';
import { getSlotFormattedDateTime } from '@helpers/dates/dates';
import { StyledDate } from './styles';

const ConfirmReservationDateTitle = ({
  date, startHour, startMinutes, endHour, endMinutes,
}) => (
  <>
    <Box display="flex" alignItems="center" mb={3}>
      <Box component="span" mr={1} display="flex" alignItems="center">
        <DateRangeIcon />
      </Box>
      <StyledDate>
        <Typography variant="body1" color="inherit">
          {getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes)}
        </Typography>
      </StyledDate>
    </Box>
  </>
);

ConfirmReservationDateTitle.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  startHour: PropTypes.number.isRequired,
  startMinutes: PropTypes.number.isRequired,
  endHour: PropTypes.number.isRequired,
  endMinutes: PropTypes.number.isRequired,
};

export default ConfirmReservationDateTitle;
