import React, { Component } from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { LOGIN_URL } from '@helpers/urls';
import { navigateTo } from '@helpers/navigation/navigation';
import Loader from '@components/atoms/Loader';
import { validateTokenAndMakeAction } from '@helpers/authorization/authorization';

class AuthorizedScreenWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    validateTokenAndMakeAction(() => '', () => navigateTo([LOGIN_URL]));
  }

  renderContent() {
    if (this.props.userStore.validated) {
      return this.props.children;
    }
    return <Loader />;
  }

  render() {
    return (
      <>
        <CssBaseline />
        <Container>
          {this.renderContent()}
        </Container>
      </>
    );
  }
}

export default inject('userStore')(observer(AuthorizedScreenWrapper));
