import React from 'react';
import { TextField as MaterialTextField } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import TextFieldTheme from './theme';

const TextField = observer((props) => (
  <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...TextFieldTheme(theme) })}>
    <MaterialTextField {...props} />
  </MuiThemeProvider>
));

export default TextField;
