import React, { Component } from 'react';
import DialogMaterialUi from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ResultDialogFiller from '@components/molecules/ResultDialogFiller';
import { RESULT_DIALOG_VARIANTS } from '@helpers/constants';
import Typography from '@components/atoms/Typography';
import DoneIcon from '@material-ui/icons/Done';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import { getSlotFormattedDateTime } from '@helpers/dates/dates';
import { Trans, withTranslation } from 'react-i18next';
import {
  StyledIconContainer,
  StyledInformationText,
  StyledNotificationIconContainer,
  StyledUnsuccessfulIconContainer
} from './styles';
import DialogTheme from './theme.jsx';

class ResultDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.addOpenOnClickPropForChildren = this.addOpenOnClickPropForChildren.bind(this);
    this.getConfigurationProps = this.getConfigurationProps.bind(this);
    this.getThankYouInformationText = this.getThankYouInformationText.bind(this);
    this.getSuccessfulCancellationInformationText = this.getSuccessfulCancellationInformationText.bind(
      this
    );
    this.renderBookingUnsuccessfulContent = this.renderBookingUnsuccessfulContent.bind(this);
    this.renderBookingSuccessfulContent = this.renderBookingSuccessfulContent.bind(this);
    this.renderNotifyMeSuccessfulContent = this.renderNotifyMeSuccessfulContent.bind(this);
    this.renderCancellingSuccessfulContent = this.renderCancellingSuccessfulContent.bind(this);
    this.childrenToRender = this.addOpenOnClickPropForChildren();

    this.EMPTY = '';
  }

  handleClose() {
    this.props.resultDialogStore.close();
  }

  handleOpen() {
    this.props.resultDialogStore.open();
  }

  getConfigurationProps() {
    const
      { resultDialogStore, tReady, t, ...configurationProps } = this.props;
    return configurationProps;
  }

  addOpenOnClickPropForChildren() {
    return React.Children.map(this.props.children,
      (child) => React.cloneElement(child, { onClick: this.handleOpen }));
  }

  renderBookingResult(icon, title, informationText, subInformationText, buttonText, onButtonClick) {
    return (
      <ResultDialogFiller
        icon={icon}
        informationText={informationText}
        subInformationText={subInformationText}
        title={title}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
      />
    );
  }

  getThankYouInformationText() {
    const { date, startHour, startMinutes, endHour, endMinutes } = this.props.resultDialogStore.data;
    return (
      <StyledInformationText>
        <Typography variant='body1' align='center' color='inherit'>
          {this.props.t('resultDialog:creating.success.information',
            { datetime: getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes) })}
        </Typography>
      </StyledInformationText>
    );
  }

  getSuccessfulCancellationInformationText() {
    const { date, startHour, startMinutes, endHour, endMinutes } = this.props.resultDialogStore.data;
    return (
      <StyledInformationText>
        <Typography variant='body1' align='center' color='inherit'>
          {this.props.t('resultDialog:cancelling.information',
            { datetime: getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes) })}
        </Typography>
      </StyledInformationText>
    );
  }

  renderBookingSuccessfulContent() {
    return this.renderBookingResult(
      <StyledIconContainer>
        <DoneIcon color='inherit' fontSize='inherit' />
      </StyledIconContainer>,
      <Typography variant='h3' align='center' gutterBottom>
        <Trans>resultDialog:creating.success.title</Trans>
      </Typography>,
      this.getThankYouInformationText(),
      this.EMPTY,
      this.props.t('resultDialog:creating.success.buttonTitle'),
      this.handleClose
    );
  }

  renderBookingUnsuccessfulContent() {
    return this.renderBookingResult(
      <StyledUnsuccessfulIconContainer>
        <CloseIcon color='inherit' fontSize='inherit' />
      </StyledUnsuccessfulIconContainer>,
      <Typography variant='h3' align='center' gutterBottom>
        <Trans>resultDialog:creating.error.title</Trans>
      </Typography>,
      <Typography
        variant='body1'
        align='center'
        gutterBottom
      >
        <Trans>resultDialog:creating.error.information</Trans>
      </Typography>,
      <Typography variant='body1' align='center'>
        <Trans>resultDialog:creating.error.subInformation</Trans>
      </Typography>,
      this.props.t('resultDialog:creating.error.buttonTitle'),
      this.handleClose
    );
  }

  renderBookingUnauthorized() {
    return this.renderBookingResult(
      <StyledUnsuccessfulIconContainer>
        <CloseIcon color='inherit' fontSize='inherit' />
      </StyledUnsuccessfulIconContainer>,
      <Typography variant='h3' align='center' gutterBottom>
        <Trans>resultDialog:creating.unauthorized.title</Trans>
      </Typography>,
      <Typography
        variant='body1'
        align='center'
        gutterBottom
      >
        <Trans>resultDialog:creating.unauthorized.information</Trans>
      </Typography>,
      this.EMPTY,
      this.props.t('resultDialog:creating.unauthorized.buttonTitle'),
      this.handleClose
    );
  }

  renderNotifyMeSuccessfulContent() {
    return this.renderBookingResult(
      <StyledNotificationIconContainer>
        <NotificationsNoneIcon color='inherit' fontSize='inherit' />
      </StyledNotificationIconContainer>,
      <Typography variant='h3' align='center' gutterBottom>
        <Trans>resultDialog:notifying.title</Trans>
      </Typography>,
      <Typography variant='body1' align='center'>
        <Trans>resultDialog:notifying.information</Trans>
      </Typography>,
      this.EMPTY,
      this.props.t('resultDialog:notifying.buttonTitle'),
      this.handleClose
    );
  }

  renderCancellingSuccessfulContent() {
    return this.renderBookingResult(
      <StyledIconContainer>
        <DoneIcon color='inherit' fontSize='inherit' />
      </StyledIconContainer>,
      <Typography variant='h3' align='center' gutterBottom>
        <Trans>resultDialog:cancelling.title</Trans>
      </Typography>,
      this.getSuccessfulCancellationInformationText(),
      this.EMPTY,
      this.props.t('resultDialog:cancelling.buttonTitle'),
      this.handleClose
    );
  }

  renderContent() {
    switch (this.props.resultDialogStore.variant) {
      case RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_SUCCESSFUL:
        return this.renderBookingSuccessfulContent();
      case RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_UNSUCCESSFUL:
        return this.renderBookingUnsuccessfulContent();
      case RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.BOOKING_UNAUTHORIZED:
        return this.renderBookingUnauthorized();
      case RESULT_DIALOG_VARIANTS.BOOKING_RESULT.substeps.NOTIFY_ME_SUCCESSFUL:
        return this.renderNotifyMeSuccessfulContent();
      case RESULT_DIALOG_VARIANTS.CANCELING_RESULT.substeps.CANCELING_SUCCESSFUL:
        return this.renderCancellingSuccessfulContent();
      default:
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...DialogTheme })}>
        {this.childrenToRender}
        <DialogMaterialUi
          disableBackdropClick
          transitionDuration={{ appear: 0 }}
          {...this.getConfigurationProps()}
          open={this.props.resultDialogStore.opened}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
        >
          <DialogContent data-cy='resultDialog'>
            {this.renderContent()}
          </DialogContent>
        </DialogMaterialUi>
      </MuiThemeProvider>
    );
  }
}

const resultDialogWithoutI18n = inject('resultDialogStore')(observer(ResultDialog));
export { resultDialogWithoutI18n };
export default withTranslation()(resultDialogWithoutI18n);
