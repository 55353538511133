import commonTranslations from '@helpers/commonTranslations/pl/common.json';
import atomsPlTranslations from '@components/atoms/translations/pl/index';
import moleculesPlTranslations from '@components/molecules/translations/pl/index';
import organismsPlTranslations from '@components/organisms/translations/pl/index';
import screensPlTranslations from '@components/screens/translations/pl/index';

const plTranslations = {
  common: commonTranslations,
  ...atomsPlTranslations,
  ...moleculesPlTranslations,
  ...organismsPlTranslations,
  ...screensPlTranslations,
};

export default plTranslations;
