import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const CancelBox = withTheme(styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: 8px;
  :hover {
		cursor: pointer;
	}
`);
