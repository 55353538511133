import React, { Component } from 'react';
import Separator from '@components/atoms/Separator';
import Typography from '@components/atoms/Typography';
import { addZeroBefore } from '@helpers/formatting/formatting';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { StyledHour } from './styles';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.getFormattedTime = this.getFormattedTime.bind(this);
    this.getSeparatorHeight = this.getSeparatorHeight.bind(this);
  }

  getFormattedTime(hours, minutes) {
    return `${addZeroBefore(hours)}:${addZeroBefore(minutes)}`;
  }

  getSeparatorHeight() {
    const { minuteToPixels, lineHeight } = this.props;
    return minuteToPixels * 60 - lineHeight;
  }

  render() {
    const { startHour, endHour, lineHeight } = this.props;
    const timelineElements = [];
    for (let i = startHour; i <= endHour; i++) {
      timelineElements.push(
        <StyledHour lineHeight={lineHeight} key={i}>
          <Typography variant='body1' color='inherit'>
            {this.getFormattedTime(i, 0)}
          </Typography>
        </StyledHour>
      );
      timelineElements.push(<Separator
        key={i + 1000}
        height={this.getSeparatorHeight()}
        widthPercent={10}
      />);
    }
    return timelineElements;
  }
}

Timeline.propTypes = {
  startHour: PropTypes.number.isRequired,
  endHour: PropTypes.number.isRequired,
  minuteToPixels: PropTypes.number.isRequired,
  lineHeight: PropTypes.number.isRequired
};

export default observer(Timeline);
