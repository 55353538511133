import React, { Component } from 'react';
import Button from '@components/atoms/buttons/Button';
import { inject, observer } from 'mobx-react';
import { navigateTo } from '@helpers/navigation/navigation';
import { BOOKING_URL } from '@helpers/urls';
import { StyledButton } from './styles';

export class DivisionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDivision: false };
    this.setDivision = this.setDivision.bind(this);
  }

  setDivision(division) {
    this.props.accountStore.assignDivision(division);
    this.props.timeSlotsStore.clearTimeSlots();
    this.props.reloadTimeSlots();
    navigateTo([BOOKING_URL], {
      date: this.props.currentlyChosenDateStore.dateString,
      division: division.slug
    });
  }

  getDivision(id) {
    return this.props.accountStore.divisions.find((div) => div.id === id);
  }

  render() {
    return (
      <StyledButton>
        {this.props.userDivisions.map((division) => (
          <Button
            name='Button'
            key={division.name}
            loading={false}
            variant={this.props.accountStore.currentDivisionId === division.divisionId
              ? 'contained'
              : 'outlined'}
            onClick={() => this.setDivision(this.getDivision(division.divisionId))}
          >
            <div>
              {division.name}
            </div>
          </Button>
        ))}
      </StyledButton>
    );
  }
}

export default inject('accountStore', 'timeSlotsStore', 'currentlyChosenDateStore')(
  observer(DivisionSelector)
);
