import React, { Component } from 'react';
import Button from '@components/atoms/buttons/Button';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';

class ResultDialogFiller extends Component {
  render() {
    const {
      informationText, subInformationText, buttonText, title, icon, onButtonClick,
    } = this.props;
    return (
      <>
        {icon}
        {title && <div data-cy="resultDialogTitle">{title}</div>}
        {informationText && <div data-cy="resultDialogInformationText">{informationText}</div>}
        {subInformationText
        && <div data-cy="resultDialogSubInformationText">{subInformationText}</div>}
        <StyledButton>
          <Button
            data-cy="resultDialogButton"
            name="Button"
            fullWidth
            variant="contained"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </StyledButton>
      </>
    );
  }
}

ResultDialogFiller.propTypes = {
  informationText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  buttonText: PropTypes.string,
  subInformationText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.object,
  icon: PropTypes.object,
  onButtonClick: PropTypes.func,
};

export default ResultDialogFiller;
