import cancelBookingButtonTranslations
  from '@components/atoms/buttons/CancelBookingButton/translations/pl.json';
import slotTranslations from '@components/atoms/Slot/translations/pl.json';

const atomsPlTranslations = {
  cancelBookingButton: cancelBookingButtonTranslations,
  slot: slotTranslations,
};

export default atomsPlTranslations;
