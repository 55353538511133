import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AlertBody } from './styles';

const Alert = ({
  title,
  icon,
  ...defaultProps
}) => (
  <AlertBody {...defaultProps}>
    {icon && <div>{icon}</div>}
    {title}
  </AlertBody>
);

Alert.propTypes = {
  title: PropTypes.string,
  severity: PropTypes.string.isRequired
};

export default observer(Alert);
