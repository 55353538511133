import React, { Component } from 'react';
import ConfirmReservationHeader from '@components/organisms/ConfirmReservationHeader';
import Alert from '@components/atoms/Alert';
import { Grid } from '@material-ui/core';
import Box from '@components/atoms/Box';
import ConfirmReservationDateTitle from '@components/organisms/ConfirmReservationDateTitle';
import Button from '@components/atoms/buttons/Button';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { StyledButton } from './styles';

class MakeBookingDialogFiller extends Component {
  constructor(props) {
    super(props);
    this.renderButton = this.renderButton.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.renderSpotsAmountSelect = this.renderSpotsAmountSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.renderSpotsAmountSelectOptions = this.renderSpotsAmountSelectOptions.bind(this);
    this.isFull = this.isFull.bind(this);
  }

  handleSelectChange(event) {
    const { bookingsStore } = this.props;

    bookingsStore.spotsAmount = event.target.value;
  }

  isFull() {
    const { numberOfAllPlaces, numberOfOccupiedPlaces } = this.props;
    return numberOfAllPlaces === numberOfOccupiedPlaces;
  }

  renderSpotsAmountSelectOptions() {
    const { numberOfAllPlaces, numberOfOccupiedPlaces } = this.props;
    const availablePlaces = numberOfAllPlaces - numberOfOccupiedPlaces;

    const children = [];
    for (let i = 0; i < availablePlaces; i++) {
      children.push(<MenuItem value={i + 1}>{i + 1}</MenuItem>);
    }

    return children;
  }

  renderTitle() {
    if (this.isFull()) return <Trans>confirmReservationHeader:isFullTitle</Trans>;

    return <Trans>confirmReservationHeader:title</Trans>;
  }

  renderAlert() {
    const { t } = this.props;

    if (this.isFull()) return null;

    return (
      <Grid item xs={12} sm={8}>
        <Alert
          icon={<CheckIcon />}
          title={t('makeBookingDialogFiller:availableSlotInformation')}
          severity="success"
          data-cy="availableSlotInformation"
        />
      </Grid>
    );
  }

  renderSpotsAmountSelect() {
    const { bookingsStore } = this.props;

    if (this.isFull()) return null;

    return (
      <Grid item xs={12} sm={8} data-cy="spotAmountSelect">
        <Box display="flex" mb={2}>
          <FormControl fullWidth>
            <Box mb={6}>
              <InputLabel id="spots-amount-select-label">
                <Trans>makeBookingDialogFiller:spotsAmount</Trans>
              </InputLabel>
            </Box>
            <Select
              labelId="spots-amount-select-label"
              id="spots-amount-select"
              value={bookingsStore.spotsAmount}
              onChange={this.handleSelectChange}
            >
              {this.renderSpotsAmountSelectOptions()}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    );
  }

  renderButton() {
    const { onAgreeClick, bookingCreating, notificationCreating } = this.props;
    if (this.isFull()) {
      return (
        <StyledButton>
          <Button
            data-cy="informMeBookingButton"
            name="Button"
            loading={notificationCreating}
            variant="outlined"
            onClick={onAgreeClick}
          >
            <Trans>makeBookingDialogFiller:informMeButtonTitle</Trans>
          </Button>
        </StyledButton>
      );
    }
    return (
      <StyledButton>
        <Button
          data-cy="confirmBookingButton"
          name="Button"
          loading={bookingCreating}
          variant="contained"
          onClick={onAgreeClick}
        >
          <Trans>makeBookingDialogFiller:confirmBookingButtonTitle</Trans>
        </Button>
      </StyledButton>
    );
  }

  render() {
    const {
      date,
      startHour,
      startMinutes,
      endHour,
      endMinutes,
      onCloseClick,
      bookingCreating,
      notificationCreating,
      accountStore,
    } = this.props;
    return (
      <>
        <ConfirmReservationHeader
          title={this.renderTitle()}
          onCloseClick={onCloseClick}
          loading={bookingCreating || notificationCreating}
        />
        <ConfirmReservationDateTitle
          date={date}
          startHour={startHour}
          startMinutes={startMinutes}
          endHour={endHour}
          endMinutes={endMinutes}
        />
        <Box mb={2}>
          {this.renderAlert()}
        </Box>
        {accountStore.currentDivision.multipleSlots && this.renderSpotsAmountSelect()}
        {this.renderButton()}
      </>
    );
  }
}

MakeBookingDialogFiller.defaultProps = {
  date: Date.now(),
  startHour: 0,
  startMinutes: 0,
  endHour: 23,
  endMinutes: 59,
  numberOfOccupiedPlaces: 0,
  numberOfAllPlaces: 0,
  onCloseClick: null,
  onAgreeClick: null,
  bookingCreating: false,
  notificationCreating: false,
};

MakeBookingDialogFiller.propTypes = {
  t: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  startHour: PropTypes.number,
  startMinutes: PropTypes.number,
  endHour: PropTypes.number,
  endMinutes: PropTypes.number,
  numberOfOccupiedPlaces: PropTypes.number,
  numberOfAllPlaces: PropTypes.number,
  onCloseClick: PropTypes.func,
  onAgreeClick: PropTypes.func,
  bookingCreating: PropTypes.bool,
  notificationCreating: PropTypes.bool,
};

const makeBookingDialogFiller = inject('bookingsStore', 'accountStore')(
  observer(MakeBookingDialogFiller),
);
export default withTranslation()(makeBookingDialogFiller);
