import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const TimelineSeparators = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export const Line = withTheme(styled('div')`
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.common.black};
  height: ${({ areaHeight }) => areaHeight}px;
  width: calc(100% - 62px);
  border-top: 1px solid ${({ theme }) => theme.palette.grey['400']};
  margin-left: 62px;
  pointer-events: none;
`);
