import cancelBookingButtonTranslations
  from '@components/atoms/buttons/CancelBookingButton/translations/de.json';
import slotTranslations from '@components/atoms/Slot/translations/de.json';

const atomsDeTranslations = {
  cancelBookingButton: cancelBookingButtonTranslations,
  slot: slotTranslations,
};

export default atomsDeTranslations;
