import axios from 'axios';

const csrfToken = document.getElementsByName('csrf-token')[0];
const token = csrfToken ? csrfToken.getAttribute('content') : '';

const index = axios.create({
  baseURL: '/api/v1',
  headers: {
    Accept: 'application/json',
    'X-CSRF-TOKEN': token,
  },
});

export default index;
