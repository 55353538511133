import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const DialogTheme = {
  overrides: {
    MuiDialog: {
      root: {
        paddingTop: 0,
      },
      container: {
        backgroundColor: 'rgba(0, 0, 0, 0.90)',
      },
      paper: {
        [breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
          margin: 0,
          maxWidth: '100%',
          maxWeight: 'none',
          borderRadius: 0,
        },
      },
      paperScrollPaper: {
        [breakpoints.down('sm')]: {
          maxHeight: '100%',
        },
      },
      paperFullWidth: {
        [breakpoints.down('sm')]: {
          maxWidth: '100%',
          width: '100%',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: '10px !important',
        position: 'relative',
      },
    },
  },
};

export default DialogTheme;
