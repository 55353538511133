import { makeAutoObservable } from 'mobx';

class ErrorsStore {
  constructor() {
    this.errorStatus = false;
    this.error = '';
    makeAutoObservable(this);
  }

  setError(errorMessage) {
    this.errorStatus = true;
    this.error = errorMessage;
  }

  clearError() {
    this.errorStatus = false;
    this.error = '';
  }
}

export default ErrorsStore;
