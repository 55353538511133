import React from 'react';
import PropTypes from 'prop-types';
import { StyledSeparator } from './styles';
import { observer } from 'mobx-react';

const Separator = ({ height, widthPercent }) => (
  <StyledSeparator height={height} widthPercent={widthPercent} />
);

Separator.propTypes = {
  height: PropTypes.number.isRequired,
  widthPercent: PropTypes.number.isRequired,
};

export default observer(Separator);
