// LOGIN
const LOGIN_URL = '/';
const RESET_PASSWORD_URL = '/reset-password'
const NEW_PASSWORD_URL = '/new-password'

// CONFIRM CODE
const CONFIRM_CODE_URL = '/confirm-code';

// BOOKING
const BOOKING_URL = '/booking';

export {
  LOGIN_URL,
  BOOKING_URL,
  CONFIRM_CODE_URL,
  RESET_PASSWORD_URL,
  NEW_PASSWORD_URL
};
