const MATCH_USERNAME_IN_EMAIL_REGEX = '(?:[a-z0-9!#$%&\'*+=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")';
const MATCH_EMAIL_REGEX = '(?:[a-z0-9!#$%&\'*+=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
const TEXT_FIELD_REGEX = '.*TextField$';
const SELECT_REGEX = '.*Select$';
const BUTTON_REGEX = '.*Button$';
const AUTOCOMPLETE_REGEX = 'Autocomplete';
const CHECKBOX_REGEX = '.*Checkbox';
const DATE_YYYY_MM_DD_REGEX = /^([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g;

function verifyEmail(email) {
  const emailRegex = RegExp(MATCH_EMAIL_REGEX);
  return emailRegex.test(email);
}

export {
  MATCH_USERNAME_IN_EMAIL_REGEX,
  MATCH_EMAIL_REGEX,
  TEXT_FIELD_REGEX,
  SELECT_REGEX,
  BUTTON_REGEX,
  CHECKBOX_REGEX,
  AUTOCOMPLETE_REGEX,
  DATE_YYYY_MM_DD_REGEX,
  verifyEmail,
};
