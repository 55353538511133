import React from 'react';
import Typography from '@components/atoms/Typography';
import CloseIcon from '@components/atoms/icons/CloseIcon';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { CancelBox } from './styles';
import { observer } from 'mobx-react';

const CancelReservationButton = ({ onCancelClick }) => (
  <CancelBox
    display="flex"
    alignItems="center"
    onClick={onCancelClick}
    data-cy="cancelBookingButton"
  >
    <CloseIcon />
    <Typography variant="body2" color="inherit">
      <Trans>cancelBookingButton:cancelReservationTitle</Trans>
    </Typography>
  </CancelBox>
);

CancelReservationButton.propTypes = {
  onCancelClick: PropTypes.func,
};

export default observer(CancelReservationButton);
