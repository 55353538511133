import React, { Component } from 'react';
import Button from '@components/atoms/buttons/Button';
import PropTypes from 'prop-types';
import Box from '@components/atoms/Box';
import Typography from '@components/atoms/Typography';
import { getSlotFormattedDateTime } from '@helpers/dates/dates';
import { Trans, withTranslation } from 'react-i18next';
import { StyledButton, StyledTitle } from './styles';
import { observer } from 'mobx-react';

class CancelBookingDialogFiller extends Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  renderTitle() {
    return (
      <StyledTitle mt={3}>
        <Typography variant="h3" gutterBottom data-cy="removeBookingTitle">
          <Trans>cancelBookingDialogFiller:title</Trans>
        </Typography>
      </StyledTitle>
    );
  }

  renderQuestion() {
    const {
      date, startHour, startMinutes, endHour, endMinutes,
    } = this.props;
    return (
      <>
        <Box mb={3}>
          <Typography variant="body1" color="inherit" data-cy="removeBookingQuestion">
            {this.props.t('cancelBookingDialogFiller:removeBookingQuestion',
              {
                date: getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes),
              })}
          </Typography>
        </Box>
      </>
    );
  }

  renderButtons() {
    const { onCloseClick, onCancelClick, bookingIsBeingCancelled } = this.props;
    return (
      <StyledButton>
        <Button
          data-cy="keepSlotButton"
          name="Button"
          disabled={bookingIsBeingCancelled}
          loading={false}
          variant="contained"
          color="secondary"
          onClick={onCloseClick}
        >
          <Trans>cancelBookingDialogFiller:cancelRemovingBookingButtonTitle</Trans>
        </Button>
        <Button
          data-cy="removeSlotButton"
          name="Button"
          loading={bookingIsBeingCancelled}
          variant="contained"
          onClick={onCancelClick}
        >
          <Trans>cancelBookingDialogFiller:removeBookingButtonTitle</Trans>
        </Button>
      </StyledButton>
    );
  }

  render() {
    return (
      <>
        {this.renderTitle()}
        {this.renderQuestion()}
        {this.renderButtons()}
      </>
    );
  }
}

CancelBookingDialogFiller.propTypes = {
  date: PropTypes.instanceOf(Date),
  startHour: PropTypes.number,
  startMinutes: PropTypes.number,
  endHour: PropTypes.number,
  endMinutes: PropTypes.number,
  onCloseClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  bookingIsBeingCancelled: PropTypes.bool,
};

export { CancelBookingDialogFiller };
export default withTranslation()(observer(CancelBookingDialogFiller));
