import { action, makeObservable as _, observable, runInAction } from 'mobx';
import ApiStore from '@stores/ApiStore';
import { DEFAULT_COLOR_THEME } from '@/themes/colorThemes';
import { colorTheme } from '@helpers/endpoints.jsx';
import { FETCHING_ERROR, FETCHING, FETCHED } from '@stores/ApiStore/consts.jsx';

class ColorThemeStore extends ApiStore {
  constructor(errorStore) {
    super(errorStore);
    this.initFields();
    this.makeObservable();
    this.bindMethods();
  }

  initFields() {
    runInAction(() => {
      this.colorTheme = undefined;
      this.fontColor = undefined;
    });
  }

  makeObservable() {
    _(this, {
      colorTheme: observable,
      fontColor: observable,
      setColorTheme: action.bound,
      setFontColor: action.bound,
      fetch: action.bound
    });
  }

  get currentColorTheme() {
    if (!this.colorTheme) return DEFAULT_COLOR_THEME;
    return this.colorTheme;
  }

  bindMethods() {
    this.handleFetchingThemes = this.handleFetchingThemes.bind(this);
    this.fetchingThemesError = this.fetchingThemesError.bind(this);
  }

  handleFetchingThemes(response) {
    this.setColorTheme(response.data.data.attributes.accentColor);
    this.setFontColor(response.data.data.attributes.fontColor);
    this.changedState(FETCHED);
  }

  fetchingThemesError() {
    this.changedState(FETCHING_ERROR);
  }

  fetch(id) {
    this.changedState(FETCHING);
    colorTheme.get(id)
      .then(this.handleFetchingThemes)
      .catch(this.fetchingThemesError);
  }

  setColorTheme(colorTheme) {
    this.colorTheme = colorTheme;
  }

  setFontColor(fontColor) {
    this.fontColor = fontColor;
  }
}

export default ColorThemeStore;
