import { auth } from '@/helpers/endpoints';
import { TOKEN_STORAGE_KEY } from '@/helpers/constants';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import ApiStore from '@stores/ApiStore';

class UserStore extends ApiStore {
  constructor(errorStore) {
    super(errorStore);
    this.initFields();
    makeObservable(this, {
      validated: observable,
      signingIn: observable,
      signedIn: observable,
      verifyingCode: observable,
      codeVerified: observable,
      validating: observable,
      id: observable,
      firstName: observable,
      lastName: observable,
      token: observable,
      email: observable,
      notValidated: computed,
      removeToken: action.bound,
      setFirstName: action.bound,
      setId: action.bound,
      setLastName: action.bound,
      setEmail: action.bound,
      setValidated: action.bound,
      setValidating: action.bound,
      setVerifyingCode: action.bound,
      setCodeVerified: action.bound,
      setSigningIn: action.bound,
      setSignedIn: action.bound,
      handleSignInError: action.bound
    });
    this.getTokenFromLocalStorage();
  }

  /**
   * GETTERS
   */

  get notValidated() {
    return !this.validated;
  }

  /**
   * SETTERS
   */

  initFields() {
    runInAction(() => {
      this.signingIn = false;
      this.signedIn = false;
      this.verifyingCode = false;
      this.codeVerified = false;
      this.validating = false;
      this.validated = false;
      this.id = undefined;
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.token = {
        accessToken: '',
        client: '',
        uid: ''
      };
    });
  }

  setFirstName(newFirstName) {
    this.firstName = newFirstName;
  }

  setLastName(newLastName) {
    this.lastName = newLastName;
  }

  setEmail(newEmail) {
    this.email = newEmail;
  }

  setValidated(newValidated) {
    this.validated = newValidated;
  }

  setId(newId) {
    this.id = newId;
  }

  setValidating(newValidating) {
    this.validating = newValidating;
  }

  setVerifyingCode(newVerifyingCode) {
    this.verifyingCode = newVerifyingCode;
  }

  setCodeVerified(newCodeVerified) {
    this.codeVerified = newCodeVerified;
  }

  setSigningIn(newSigningIn) {
    this.signingIn = newSigningIn;
  }

  setSignedIn(newSignedIn) {
    this.signedIn = newSignedIn;
  }

  /**
   * OTHER METHODS
   */

  signIn(params, usePasswordFlow = false) {
    this.setSigningIn(true);
    this.setSignedIn(false);
    this.clearError();
    return auth.signIn(params)
      .then(({ data, headers }) => {
        if (usePasswordFlow) this.assignToken(headers);
        this.assignUser(data);
        this.setSigningIn(false);
        this.setSignedIn(true);
      })
      .catch(this.handleSignInError);
  }

  resetPassword(params) {
    this.setSigningIn(true);
    this.setSignedIn(false);
    this.clearError();

    return auth.resetPassword(params).catch(this.handleSignInError);
  }

  newPassword(params) {
    this.setSigningIn(true);
    this.setSignedIn(false);
    this.clearError();

    return auth.newPassword(params).then(({ data, headers }) => {
      this.assignToken(headers);
      this.assignUser(data);
      this.setSigningIn(false);
      this.setSignedIn(true);
    }).catch(this.handleSignInError);
  }

  handleSignInError(error) {
    if (this.getErrorStatus(error) === 401 || this.getErrorStatus(error) === 404) {
      this.setError({ email: this.getErrorsListFromCaughtError(error) });
    } else {
      this.handleServerError(error);
    }
    this.setSigningIn(false);
    this.setSignedIn(false);
    throw error;
  }

  verifyCode(code) {
    this.setVerifyingCode(true);
    this.setCodeVerified(false);
    this.clearError();
    return auth.signIn(code)
      .then(({ headers }) => {
        this.assignToken(headers);
        this.setVerifyingCode(false);
        this.setCodeVerified(true);
      })
      .catch(this.handleVerifyCodeError);
  }

  handleVerifyCodeError(error) {
    if (this.getErrorStatus(error) === 401) {
      this.setError({ code: this.getErrorsListFromCaughtError(error) });
    } else {
      this.handleServerError(error);
    }
    this.setVerifyingCode(false);
    this.setCodeVerified(false);
    throw error;
  }

  validateToken() {
    this.setValidating(true);
    this.setValidated(false);
    return auth.validateToken()
      .then(({ data }) => {
        this.assignUser(data);
        this.setValidating(false);
        this.setValidated(true);
      });
  }

  assignToken(headers) {
    const newToken = {
      accessToken: headers['access-token'],
      client: headers.client,
      uid: headers.uid
    };
    this.token = newToken;
    window.localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(newToken));
  }

  assignUser(data) {
    this.setFirstName(data.data.first_name);
    this.setLastName(data.data.last_name);
    this.setEmail(data.data.email);
    this.setId(data.data.id);
  }

  removeToken() {
    this.token = {
      accessToken: '',
      client: '',
      uid: ''
    };
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  getTokenFromLocalStorage() {
    this.token = JSON.parse(window.localStorage.getItem(TOKEN_STORAGE_KEY));
  }

  hasTokenInLocalStorage() {
    const token = window.localStorage.getItem(TOKEN_STORAGE_KEY);
    return Boolean(token && token.accessToken !== '');
  }
}

export default UserStore;
