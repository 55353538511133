import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const BookingHeaderLogoContainer = withTheme(styled('div')`
  padding-top: 32px;
  margin-bottom: 48px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 32px;
    flex-direction: column;
    align-items: flex-start;
  }
`);
