import React from 'react';
import { MONTHS_NAMES } from '@helpers/constants';
import NavigateBeforeButton from '@components/atoms/buttons/NavigateBeforeButton';
import NavigateNextButton from '@components/atoms/buttons/NavigateNextButton';
import PropTypes from 'prop-types';
import { getDayName } from '@helpers/dates/dates';
import Box from '@components/atoms/Box';
import { Trans } from 'react-i18next';
import Typography from '@components/atoms/Typography';

const DateSwitcher = ({ date, onBeforeClick, onNextClick }) => (
  <Box display="flex" justifyContent="space-between" mb={6}>
    <span data-cy="bookingScreenCurrentDate">
      <Typography variant="h4">
        <Trans>
          common:months.
          {MONTHS_NAMES[date.getMonth()]}
        </Trans>
        {' '}
        {date.getDate()}
        ,
        {' '}
        {date.getFullYear()}
      </Typography>
      <Typography variant="body1">
        <Trans>
          common:daysOfTheWeek.
          {getDayName(date.getDay())}
        </Trans>
      </Typography>
    </span>
    <span>
      <NavigateBeforeButton color="primary" onClick={onBeforeClick} data-cy="nextDay" />
      <NavigateNextButton color="primary" onClick={onNextClick} data-cy="previousDay" />
    </span>
  </Box>
);

DateSwitcher.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onBeforeClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export default DateSwitcher;
