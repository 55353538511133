import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@components/atoms/Typography';
import Box from '@components/atoms/Box';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { BookingHeaderLogoContainer } from './styles';

class BookingHeader extends Component {
  render() {
    return (
      <>
        <BookingHeaderLogoContainer>
          <img
            src={this.props.accountStore.accountLogo}
            alt={this.props.accountStore.accountName}
            width="48px"
            height="47px"
          />
        </BookingHeaderLogoContainer>
        <Box mb={5}>
          <Typography variant="h2" gutterBottom data-cy="helloHeader">
            <Trans>bookingHeader:welcomeTitle</Trans>
            {' '}
            {this.props.userName}
            !
          </Typography>
          <Typography variant="body1" data-cy="subHeader">
            <Trans i18nKey="bookingHeader:subTitle">
              bookingHeader:subTitle
              {{ accountName: this.props.accountStore.accountName }}
              {{ bookingsAmount: this.props.bookingsStore.bookings.length }}
              {{ availableBookings: this.props.accountStore.currentDivision.userBookingsPerDay }}
            </Trans>
          </Typography>
        </Box>
      </>
    );
  }
}

BookingHeader.propTypes = {
  userName: PropTypes.string,
};
export default inject('accountStore', 'bookingsStore')(observer(BookingHeader));
