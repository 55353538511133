import React, { Component } from 'react';
import Alert from '@components/atoms/Alert';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { NON_FIELD_ERRORS } from '@helpers/constants';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {
  AUTOCOMPLETE_REGEX,
  BUTTON_REGEX,
  CHECKBOX_REGEX,
  SELECT_REGEX,
  TEXT_FIELD_REGEX,
} from '@helpers/regexes';
import PropTypes from 'prop-types';

class Form extends Component {

  /**
   * Blocked field is a field which initial value can't be changed.
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: props.errors,
      headerErrorText: '',
      informationText: '',
      blockedFields: props.blockedFields || [],
    };
    let { initialValues, children } = props;
    this.recursivelyCreateStateBasedOnChildren = this.recursivelyCreateStateBasedOnChildren.bind(
      this);
    this.recursivelyAddProps = this.recursivelyAddProps.bind(this);
    this.addPropsToButton = this.addPropsToButton.bind(this);
    this.addPropsToTextField = this.addPropsToTextField.bind(this);
    this.addPropsToCheckbox = this.addPropsToCheckbox.bind(this);
    this.createStateForTextField = this.createStateForTextField.bind(this);
    this.checkComponentAndModifyProps = this.checkComponentAndModifyProps.bind(this);
    this.createStateForCheckbox = this.createStateForCheckbox.bind(this);
    this.renderHelperText = this.renderHelperText.bind(this);
    this.modifyOnClickProp = this.modifyOnClickProp.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.resolveErrors = this.resolveErrors.bind(this);
    this.onEnterPress = this.onEnterPress.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.isBlocked = this.isBlocked.bind(this);
    this.addPropsToSelect = this.addPropsToSelect.bind(this);
    this.handleDoubleOnChange = this.handleDoubleOnChange.bind(this);
    this.clearState = this.clearState.bind(this);
    this.onClick = () => {
    };
    this.recursivelyCreateStateBasedOnChildren(children, initialValues || {});
  };

  static getDerivedStateFromProps(nextProps) {
    const { errors, informationText } = nextProps;
    return {
      errors,
      informationText,
    };
  }

  componentDidMount() {
    if (this.state.errors) {
      this.resolveErrors();
    }
  }

  createStateForTextField(textField, initialValues) {
    let value = '';
    if (textField.key in initialValues) {
      value = initialValues[textField.key];
    }
    // eslint-disable-next-line
    this.state.fields[textField.key] = {
      value: value,
      errorStatus: false,
      helperText: '',
      id: textField.key,
    };
  }

  modifyOnClickProp(button) {
    this.onClick = () => button.props.onClick(this.state.fields);
  }

  onEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      this.onClick();
    }
  };

  createStateForCheckbox(checkbox, initialValues) {
    let value = false;
    if (checkbox.key in initialValues) {
      value = initialValues[checkbox.key];
    }
    // eslint-disable-next-line
    this.state.fields[checkbox.key] = { value: value };
  }

  recursivelyCreateStateBasedOnChildren(children, initialValues) {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      if (child.props.children) {
        child = React.cloneElement(child, {
          children: this.recursivelyCreateStateBasedOnChildren(child.props.children, initialValues),
        });
      }
      if (child.props.control) {
        return React.cloneElement(child, {
          children: this.recursivelyCreateStateBasedOnChildren(child.props.control, initialValues),
        });
      }
      let childName;
      if (child.props.name) {
        childName = child.props.name;
      }
      if (childName) {
        if (childName.match(BUTTON_REGEX)) {
          this.modifyOnClickProp(child);
        } else if (childName.match(TEXT_FIELD_REGEX) || childName.match(SELECT_REGEX) ||
          childName.match(AUTOCOMPLETE_REGEX)) {
          this.createStateForTextField(child, initialValues);
        } else if (childName.match(CHECKBOX_REGEX)) {
          this.createStateForCheckbox(child, initialValues);
        }
      }
    });
  }

  handleValueChange = (key, value) => {
    let newState = this.state;
    newState.fields[key].value = value;
    this.setState(newState);
  };

  /**
   * Sets all errors to false and messages to empty string.
   */
  clearErrors() {
    let newState = this.state;
    newState.headerErrorText = '';
    const fieldsNames = Object.keys(this.state.fields);
    for (let fieldName of fieldsNames) {
      if ('helperText' in this.state.fields[fieldName]) {
        newState.fields[fieldName].helperText = '';
      }
      if ('errorStatus' in this.state.fields[fieldName]) {
        newState.fields[fieldName].errorStatus = false;
      }
    }
    this.setState(newState);
  }

  /**
   * Parses errors
   */
  resolveErrors() {
    if (this.state.errors) {
      if (this.state.errors[NON_FIELD_ERRORS]) {
        let newState = this.state;
        newState.headerErrorText = this.state.errors[NON_FIELD_ERRORS][0];
        this.setState(newState);
      } else {
        let newState = this.state;
        const fieldsNames = Object.keys(this.state.fields);
        for (let fieldName of fieldsNames) {
          if (this.state.errors[fieldName]) {
            newState.fields[fieldName].helperText = this.state.errors[fieldName][0];
            newState.fields[fieldName].errorStatus = true;
          }
        }
        this.setState(newState);
      }
    }
  }

  addPropsToButton(button) {
    if (button.key === 'accept') {
      return React.cloneElement(button, {
        onClick: this.onClick,
      });
    }
    return button;
  }

  addPropsToCheckbox(checkbox) {
    return React.cloneElement(checkbox, {
      onChange: (event) => {
        if (this.isBlocked(checkbox.key)) {
          return '';
        } else {
          return this.handleValueChange(checkbox.key, event.target.checked);
        }
      },
      checked: this.state.fields[checkbox.key].value,
    });
  }

  addPropsToTextField(textField) {
    if (!textField.props.onChange && !textField.props.value) {
      if (!this.state.fields[textField.key]) {
        this.createStateForTextField(textField, this.props.initialValues || {});
      }
      return React.cloneElement(textField, {
        /**
         * If the field is not blocked gets onChange function.
         */
        onChange: (event) => {
          if (this.isBlocked(textField.key)) {
            return '';
          } else {
            return this.handleValueChange(textField.key, event.target.value);
          }
        },
        onKeyDown: this.onEnterPress,
        value: this.state.fields[textField.key].value,
        error: this.state.fields[textField.key].errorStatus,
        helperText: this.state.fields[textField.key].helperText,
      });
    }
    return textField;
  }

  handleDoubleOnChange(textField, event) {
    return (() => {
      textField.props.onChange(event);
      this.handleValueChange(textField.key, event.target.value);
    });
  }

  addPropsToSelect(select) {
    if (!select.props.value) {
      if (!this.state.fields[select.key]) {
        this.createStateForTextField(select, this.props.initialValues || {});
      }
      return React.cloneElement(select, {
        /**
         * If the field is not blocked gets onChange function.
         */
        onChange: (event) => {
          if (this.isBlocked(select.key)) {
            return '';
          } else {
            if (select.props.onChange) {
              return (this.handleDoubleOnChange(select, event)());
            } else {
              return this.handleValueChange(select.key, event.target.value);
            }
          }
        },
        onKeyDown: this.onEnterPress,
        value: this.state.fields[select.key].value,
        error: this.state.fields[select.key].errorStatus,
      });
    }
    return select;
  }

  addPropsToAutocomplete(autocomplete) {
    if (!autocomplete.props.onChange && !autocomplete.props.value) {
      if (!this.state.fields[autocomplete.key]) {
        this.createStateForTextField(autocomplete, this.props.initialValues || {});
      }
      return React.cloneElement(autocomplete, {
        /**
         * If the field is not blocked gets onChange function.
         */
        onChange: (event, newValue) => {
          if (this.isBlocked(autocomplete.key)) {
            return '';
          } else {
            if (newValue && newValue.inputValue) {
              return this.handleValueChange(autocomplete.key, newValue.inputValue);
            }
            return this.handleValueChange(autocomplete.key, newValue);
          }
        },
        onKeyDown: this.onEnterPress,
        value: this.state.fields[autocomplete.key].value,
      });
    }
    return autocomplete;
  }

  /**
   * If child is a Button then onClick prop is replaced by itself but with state passed.
   * If it is textField or Checkbox component props to manage its value with Form's state are passed.
   */
  checkComponentAndModifyProps(component) {
    if (!React.isValidElement(component)) {
      return component;
    }
    if (component.props.children) {
      component = React.cloneElement(component, {
        children: this.recursivelyAddProps(component.props.children),
      });
    }
    if (component.props.control) {
      component = React.cloneElement(component, {
        control: this.recursivelyAddProps(component.props.control),
      });
    }

    let componentName;
    if (component.props.name) {
      componentName = component.props.name;
    }
    if (componentName) {
      if (componentName.match(BUTTON_REGEX)) {
        component = this.addPropsToButton(component);
      } else if (componentName.match(TEXT_FIELD_REGEX)) {
        component = this.addPropsToTextField(component);
      } else if (componentName.match(CHECKBOX_REGEX)) {
        component = this.addPropsToCheckbox(component);
      } else if (componentName.match(SELECT_REGEX)) {
        component = this.addPropsToSelect(component);
      } else if (componentName.match(AUTOCOMPLETE_REGEX)) {
        component = this.addPropsToAutocomplete(component);
      }
    }
    return component;
  }

  recursivelyAddProps(children) {
    if (children.length) {
      return React.Children.map(children, (child) => {
        return this.checkComponentAndModifyProps(child);
      });
    } else {
      return this.checkComponentAndModifyProps(children);
    }
  }

  /**
   * Returns boolean information if the field is blocked or not. Blocked field is a field which initial value can't be changed.
   * @param fieldName
   * @returns {boolean}
   */
  isBlocked(fieldName) {
    return Boolean(
      this.state.blockedFields.find(blockedFieldName => blockedFieldName === fieldName));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.errors !== prevProps.errors) {
      this.clearErrors();
      this.resolveErrors();
    }
  }

  renderHelperText() {
    if (this.state.headerErrorText) {
      return (
        <Alert
          severity='error'
          title={this.state.headerErrorText}
          icon={<ErrorOutlineOutlinedIcon/>}
        />
      );
    } else if (this.state.informationText) {
      return (<Alert
        severity='success'
        title={this.state.informationText}
        icon={<CheckCircleOutlinedIcon/>}
      />);
    }
  }

  /**
   * sets all strings in fields state to empty string and all boolean variables to false
   */
  clearState() {
    const newFieldsState = {};
    const oldFieldsState = this.state.fields;
    for (let componentStateName in oldFieldsState) {
      if (Object.prototype.hasOwnProperty.call(oldFieldsState, componentStateName)) {
        const fieldState = oldFieldsState[componentStateName];
        newFieldsState[componentStateName] = {};
        for (let fieldName in fieldState) {
          if (Object.prototype.hasOwnProperty.call(fieldState, fieldName)) {
            if (typeof (fieldState[fieldName]) === 'string') {
              newFieldsState[componentStateName][fieldName] = '';
            } else if (typeof (fieldState[fieldName]) === 'boolean') {
              newFieldsState[componentStateName][fieldName] = false;
            }
          }
        }
      }
    }
    this.setState({
      fields: newFieldsState,
    });
  }

  render() {
    const children = this.recursivelyAddProps(this.props.children);
    return (
      <form>
        {this.renderHelperText()}
        {React.Children.map(children, child => <div>{child}</div>)}
      </form>
    );
  }
}

Form.propTypes = {
  errors: PropTypes.object,
  blockedFields: PropTypes.array,
  initialValues: PropTypes.object,
  informationText: PropTypes.string,
};

export default Form;
