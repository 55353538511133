import { DAYS_NAMES_FROM_SUNDAY as days, MONTHS_NAMES } from '@helpers/constants';
import { DATE_YYYY_MM_DD_REGEX } from '@helpers/regexes';
import { addZeroBefore, formatTime } from '@helpers/formatting/formatting';
import React from 'react';
import i18n from '@/i18n';

/**
 * Returns first letter of day's name based on given date.
 * @param date
 * @returns {string}
 */
function getDayFirstLetter(date) {
  return days[date.getDay()][0];
}

/**
 * Returns array of dates from startDate to endDate with one day step.
 * @param startDate
 * @param endDate
 * @returns {[]}
 */
function getDatesBetween(startDate, endDate) {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
}

/**
 * Returns new date with the days shifted by the specified number
 * @param date
 * @param days
 * @returns {Date}
 */
function addDays(date, days) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
}

function getWeekNumber(date) {
  // Copy date so don't modify original
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  return Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
}

function getLastDateOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}

function getFirstDateOfMonth(year, month) {
  return new Date(year, month, 1);
}

/**
 * Returns startDate and endDate based on week's number.
 * @returns {[Date, Date]}
 * @param weekNumber
 * @param year
 */
function getWeekDatesRange(weekNumber, year) {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dow = simple.getDay();
  const weekStart = simple;
  if (dow <= 4) {
    weekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    weekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return [weekStart, new Date(new Date(weekStart).setDate(weekStart.getDate() + 6))];
}

/**
 * monthNumber is number between 0 and 11
 * @param monthNumber
 * @returns {*}
 */
function getMonthName(monthNumber) {
  return MONTHS_NAMES[monthNumber];
}

function getDayName(dayInWeekNumber) {
  return days[dayInWeekNumber];
}

/**
 * Returns number of weeks between given dates
 * @param startDate
 * @param endDate
 * @returns {number}
 */
function getNumberOfWeeksBetween(startDate, endDate) {
  return Math.round((endDate - startDate) / (7 * 24 * 60 * 60 * 1000));
}

/**
 * Returns number of weeks in given year
 * @param year
 * @returns {number}
 */
function getNumberOfWeeksInYear(year) {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);
  return getNumberOfWeeksBetween(startDate, endDate);
}

/**
 * Returns true if both date objects have same date (doesn't care about time)
 * @param date1
 * @param date2
 * @returns {boolean}
 */
function sameDate(date1, date2) {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

function getNumberOfDaysInMonth(month, year) {
  switch (month) {
    case 1:
      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
    case 3:
    case 5:
    case 8:
    case 10:
      return 30;
    default:
      return 31;
  }
}

/**
 * Returns true if date belongs to the given month.
 * @param date
 * @param month
 * @returns {boolean}
 */
function isDayInMonth(date, month) {
  return date.getMonth() === month;
}

function stringToDate(dateString) {
  return new Date(Date.parse(dateString));
}

function getCurrentDate() {
  return new Date();
}

function getCurrentDateNoTime() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

function areValidDateElements(year, month, day) {
  month = parseInt(month, 10) - 1;
  return month >= 0 && month < 12 && day > 0 && day <= getNumberOfDaysInMonth(month, year);
}

function isValidDateString(dateString) {
  if (!dateString || !dateString.match(DATE_YYYY_MM_DD_REGEX)) return false;
  const { year, month, day } = getYearMonthDayFromDateString(dateString);
  return areValidDateElements(year, month, day);
}

function getYearMonthDayFromDateString(dateString) {
  const match = /(\d{4})-(\d{2})-(\d{2})/g.exec(dateString);
  return ({
    year: parseInt(match[1]),
    month: parseInt(match[2]),
    day: parseInt(match[3]),
  });
}

function getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes) {
  const monthString = MONTHS_NAMES[date.getMonth()];
  const restOfDateTimeString = ` ${date.getDate()}, ${date.getFullYear()}, ${formatTime(startHour,
    startMinutes)} - ${formatTime(endHour, endMinutes)}`;
  return i18n.t(`common:months.${monthString}`) + restOfDateTimeString;
}

/**
 * return string like 2020-06-09T07:00:00.000+02:00
 * @param year
 * @param month
 * @param day
 * @param hours
 * @param minutes
 * @param seconds
 * @param plusGMT
 * @returns {string}
 */
function getTimeStampString(year, month, day, hours, minutes, seconds = 0, plusGMT = 2) {
  return `${year}-${month}-${day}T${addZeroBefore(hours)}:${addZeroBefore(minutes)}:${
    addZeroBefore(seconds)}.000` + `+${addZeroBefore(plusGMT)}:00`;
}

export {
  getSlotFormattedDateTime,
  getDayFirstLetter,
  getDatesBetween,
  addDays,
  getWeekNumber,
  getLastDateOfMonth,
  getFirstDateOfMonth,
  getWeekDatesRange,
  getMonthName,
  getNumberOfWeeksBetween,
  getNumberOfWeeksInYear,
  sameDate,
  isDayInMonth,
  stringToDate,
  getCurrentDate,
  getCurrentDateNoTime,
  getDayName,
  isValidDate,
  areValidDateElements,
  getYearMonthDayFromDateString,
  isValidDateString,
  getTimeStampString,
};
