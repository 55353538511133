import styled from 'styled-components';

export const HoursColumn = styled.div`
  width: 62px;
`;
export const SlotsContainer = styled.div`
  position: relative;
`;
export const TimelineSlotsContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;
