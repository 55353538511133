/**
 * TIME, DATES
 */

const DAYS_NAMES_FROM_SUNDAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'];
const MONTHS_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'];

/**
 * ERRORS / INFOS
 */
const NO_RESPONSE_FROM_SERVER = 'No response from server';

/**
 * ERRORS
 * @type {string}
 */
const NON_FIELD_ERRORS = 'non_field_errors';

/**
 * ApiStore STATES
 * @type {string}
 */
const FETCHED = 'FETCHED';
const FETCHING = 'FETCHING';

const CREATED = 'CREATED';
const CREATING = 'CREATING';

const DELETED = 'DELETED';
const DELETING = 'DELETING';

const UPDATING = 'UPDATING';
const UPDATED = 'UPDATED';

const ERROR = 'ERROR';
const UNAUTHORIZED = 'UNAUTHORIZED';
const SUCCESS = 'SUCCESS';

/**
 * ROUTES TYPES
 */
const AUTHORIZED_ROUTE = 'AUTHORIZED_ROUTE';
const NOT_AUTHORIZED_ROUTE = 'NOT_AUTHORIZED_ROUTE';

const TOKEN_STORAGE_KEY = 'booknow_client_token';

/**
 * DIALOG VARIANTS
 */
const INITIAL_DIALOG_VARIANTS = {
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  NOTIFY_ME: 'NOTIFY_ME',
  MAKE_BOOKING: 'MAKE_BOOKING'
};

const RESULT_DIALOG_VARIANTS = {
  BOOKING_RESULT: {
    name: 'BOOKING_RESULT',
    substeps: {
      BOOKING_SUCCESSFUL: 'BOOKING_SUCCESSFUL',
      BOOKING_UNSUCCESSFUL: 'BOOKING_UNSUCCESSFUL',
      NOTIFY_ME_SUCCESSFUL: 'NOTIFY_ME_SUCCESSFUL',
      UNAUTHORIZED: 'UNAUTHORIZED'
    }
  },
  CANCELING_RESULT: {
    name: 'CANCELING_RESULT',
    substeps: { CANCELING_SUCCESSFUL: 'CANCELING_SUCCESSFUL' }
  }
};

/**
 * WRAPPERS
 */
const ROUTER = 'ROUTER';
const THEME_PROVIDER = 'THEME_PROVIDER';
const MOBX_PROVIDER = 'MOBX_PROVIDER';
const I18N_PROVIDER = 'I18N_PROVIDER';
const SHALLOW = 'SHALLOW';
const MOUNT = 'MOUNT';
const I18N_PROVIDER_AND_T_METHOD = 'I18N_PROVIDER_AND_T_METHOD';

export {
  DAYS_NAMES_FROM_SUNDAY,
  MONTHS_NAMES,
  AUTHORIZED_ROUTE,
  NOT_AUTHORIZED_ROUTE,
  NON_FIELD_ERRORS,
  ERROR,
  FETCHED,
  SUCCESS,
  FETCHING,
  CREATED,
  CREATING,
  UNAUTHORIZED,
  UPDATED,
  UPDATING,
  DELETED,
  DELETING,
  NO_RESPONSE_FROM_SERVER,
  TOKEN_STORAGE_KEY,
  RESULT_DIALOG_VARIANTS,
  INITIAL_DIALOG_VARIANTS,
  ROUTER,
  MOBX_PROVIDER,
  I18N_PROVIDER,
  SHALLOW,
  MOUNT,
  I18N_PROVIDER_AND_T_METHOD,
  THEME_PROVIDER
};
