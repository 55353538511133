import yourBookingsLegendTranslations
  from '@components/molecules/YourBookingsLegend/translations/pl.json';
import confirmReservationHeaderTranslations
  from '@components/organisms/ConfirmReservationHeader/translations/pl.json';
import cancelBookingDialogFillerTranslations
  from '@components/molecules/CancelBookingDialogFiller/translations/pl.json';
import makeBookingDialogFillerTranslations
  from '@components/molecules/MakeBookingDialogFiller/translations/pl.json';
import yourBookingsElementTranslations
  from '@components/molecules/YourBookingsElement/translations/pl.json';


const moleculesPlTranslations = {
  yourBookingsLegend: yourBookingsLegendTranslations,
  confirmReservationHeader: confirmReservationHeaderTranslations,
  cancelBookingDialogFiller: cancelBookingDialogFillerTranslations,
  makeBookingDialogFiller: makeBookingDialogFillerTranslations,
  yourBookingsElement: yourBookingsElementTranslations,
};

export default moleculesPlTranslations;
