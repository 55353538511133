import bookingScreenTranslations from '@components/screens/BookingScreen/translations/de.json';
import loginScreenTranslations from '@components/screens/LoginScreen/translations/de.json';
import resetPasswordScreenTranslations from '@components/screens/ResetPasswordScreen/translations/de.json';
import newPasswordScreenTranslations from '@components/screens/NewPasswordScreen/translations/de.json';
import confirmCodeScreenTranslations
  from '@components/screens/ConfirmCodeScreen/translations/de.json';

const screensDeTranslations = {
  bookingScreen: bookingScreenTranslations,
  loginScreen: loginScreenTranslations,
  confirmCodeScreen: confirmCodeScreenTranslations,
  resetPasswordScreen: resetPasswordScreenTranslations,
  newPasswordScreen: newPasswordScreenTranslations
};

export default screensDeTranslations;
