import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Line, TimelineSeparators } from './styles';

class TimelineSeparator extends Component {
  constructor(props) {
    super(props);
    this.getLineAreaHeight = this.getLineAreaHeight.bind(this);
  }

  getLineAreaHeight() {
    const { minuteToPixels } = this.props;
    return minuteToPixels * 60;
  }

  render() {
    const { startHour, endHour } = this.props;
    const timelineElements = [];
    for (let i = startHour; i <= endHour; i++) {
      timelineElements.push(
        <Line areaHeight={this.getLineAreaHeight} key={i} />
      );
    }
    return (
      <TimelineSeparators>
        {timelineElements}
      </TimelineSeparators>
    );
  }
}

TimelineSeparator.propTypes = {
  startHour: PropTypes.number.isRequired,
  endHour: PropTypes.number.isRequired
};

export default observer(TimelineSeparator);
