import React from 'react';
import Typography from '@components/atoms/Typography';
import { Trans } from 'react-i18next';
import { rgba } from 'polished';
import { useTheme } from '@material-ui/core/styles';
import { Legend, LegendBody, LegendElement, LegendSymbol } from './styles';

const YourBookingsLegend = () => {
  const globalTheme = useTheme();
  return (
    <Legend>
      <LegendElement>
        <LegendSymbol color={rgba(`${globalTheme.palette.grey['200']}`, 0.80)} />
        <LegendBody>
          <Typography variant='body1' data-cy='bookableLegendTitle'>
            <b><Trans>yourBookingsLegend:bookableLegendTitle</Trans></b>
          </Typography>
          <Typography variant='body2' data-cy='bookableLegendSubTitle'>
            <Trans>yourBookingsLegend:bookableLegendSubTitle</Trans>
          </Typography>
        </LegendBody>
      </LegendElement>
      <LegendElement>
        <LegendSymbol color={globalTheme.palette.accent} />
        <LegendBody>
          <Typography variant='body1' data-cy='occupiedLegendTitle'>
            <b><Trans>yourBookingsLegend:bookedLegendTitle</Trans></b>
          </Typography>
          <Typography variant='body2' data-cy='occupiedLegendSubTitle'>
            <Trans>yourBookingsLegend:bookedLegendSubTitle</Trans>
          </Typography>
        </LegendBody>
      </LegendElement>
      <LegendElement>
        <LegendSymbol color={rgba(`${globalTheme.palette.primary.main}`, 0.80)} />
        <LegendBody>
          <Typography variant='body1' data-cy='occupiedLegendTitle'>
            <b><Trans>yourBookingsLegend:occupiedLegendTitle</Trans></b>
          </Typography>
          <Typography variant='body2' data-cy='occupiedLegendSubTitle'>
            <Trans>yourBookingsLegend:occupiedLegendSubTitle</Trans>
          </Typography>
        </LegendBody>
      </LegendElement>
    </Legend>
  );
};

export default YourBookingsLegend;
