import bookingHeaderTranslations from '@components/organisms/BookingHeader/translations/de.json';
import yourBookingsTranslations from '@components/organisms/YourBookings/translations/de.json';
import confirmReservationHeaderTranslations
  from '@components/organisms/ConfirmReservationHeader/translations/de.json';
import resultDialogTranslations from '@components/organisms/ResultDialog/translations/de.json';
import loginHeaderTranslations from '@components/organisms/LoginHeader/translations/de.json';

const organismsDeTranslations = {
  bookingHeader: bookingHeaderTranslations,
  yourBookings: yourBookingsTranslations,
  confirmReservationHeader: confirmReservationHeaderTranslations,
  resultDialog: resultDialogTranslations,
  loginHeader: loginHeaderTranslations,
};

export default organismsDeTranslations;
