import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@components/atoms/Box';
import Timeline from '@components/molecules/Timeline';
import TimelineSeparator from '@components/molecules/TimelineSeparator';
import Slots from '@components/molecules/Slots';
import Loader from '@components/atoms/Loader';
import { addDays, getCurrentDateNoTime, stringToDate } from '@helpers/dates/dates';
import { addSeconds } from '@helpers/time/time';
import { HoursColumn, SlotsContainer, TimelineSlotsContainer } from './styles';
import { observer } from 'mobx-react';

class SlotsAndTimeline extends Component {
  constructor(props) {
    super(props);
    this.timeSlotsForBookingComponents = this.timeSlotsForBookingComponents.bind(this);
    this.bookingsBookable = this.bookingsBookable.bind(this);

    this.state = { serializedSlots: [] };
    this.timeSlotsForBookingComponents();
  }

  timeSlotsForBookingComponents() {
    const result = [];
    this.props.slots.map((timeSlot) => {
      const startDateTime = stringToDate(timeSlot.attributes.startedAt);
      const endDateTime = addSeconds(stringToDate(timeSlot.attributes.endedAt), 1);
      const timeSlotBooked = this.checkIfTimeSlotAlreadyBooked(timeSlot);
      if (this.timeSlotsInBlock(timeSlot) && !this.props.allowBookingSlotsBlock) return;
      result.push({
        id: timeSlot.id,
        startHour: startDateTime.getHours(),
        startMinutes: startDateTime.getMinutes(),
        endHour: endDateTime.getHours(),
        endMinutes: endDateTime.getMinutes(),
        numberOfAllPlaces: timeSlot.attributes.spotNumbers,
        numberOfOccupiedPlaces: timeSlot.attributes.reservedSpotsAmount,
        date: startDateTime,
        booked: timeSlotBooked,
        users: timeSlot.attributes.users,
        bookable: this.bookingsBookable(timeSlot)
      });
    });
    return result;
  }

  bookingsBookable(timeSlot) {
    const timeSlotDate = stringToDate(timeSlot.attributes.date);
    return timeSlotDate <= addDays(getCurrentDateNoTime(), this.props.divisionBookableDays);
  }

  timeSlotsInBlock(timeSlot) {
    const startedAt = stringToDate(timeSlot.attributes.startedAt)
      .getTime();
    const endedAt = addSeconds(stringToDate(timeSlot.attributes.endedAt), 1)
      .getTime();

    return this.props.userBookings.some((booking) => {
      const bookingStartedAt = stringToDate(booking.attributes.startedAt)
        .getTime();
      const bookingEndedAt = addSeconds(stringToDate(booking.attributes.endedAt), 1)
        .getTime();

      return (bookingEndedAt === startedAt || bookingStartedAt === endedAt);
    });
  }

  checkIfTimeSlotAlreadyBooked(timeSlot) {
    return this.props.bookedTimeSlotIds.includes(timeSlot.attributes.id);
  }

  render() {
    const {
      slotsLoading,
      minuteToPixels,
      hourLineHeight,
      timelineStartHour,
      timelineEndHour,
      timelineStartMinutes,
      onSlotClick
    } = this.props;
    return (
      <Box display='flex'>
        <TimelineSlotsContainer data-cy='timeline'>
          <TimelineSeparator
            minuteToPixels={minuteToPixels}
            startHour={timelineStartHour}
            endHour={timelineEndHour}
          />
          <HoursColumn>
            <Timeline
              startHour={timelineStartHour}
              endHour={timelineEndHour}
              minuteToPixels={minuteToPixels}
              lineHeight={hourLineHeight}
            />
          </HoursColumn>
          <Box flexGrow={1}>
            <SlotsContainer data-cy='slots'>
              {slotsLoading
                ? <Loader />
                : (
                  <Slots
                    bookingsAvailable={this.props.bookingsAvailable}
                    allowBookingSlotsBlock={this.props.allowBookingSlotsBlock}
                    slots={this.timeSlotsForBookingComponents()}
                    minuteToPixels={minuteToPixels}
                    timelineStartHour={timelineStartHour}
                    timelineStartMinutes={timelineStartMinutes}
                    onClick={onSlotClick}
                  />
                )}
            </SlotsContainer>
          </Box>
        </TimelineSlotsContainer>
      </Box>
    );
  }
}

SlotsAndTimeline.propTypes = {
  minuteToPixels: PropTypes.number.isRequired,
  slotsLoading: PropTypes.bool.isRequired,
  hourLineHeight: PropTypes.number.isRequired,
  slots: PropTypes.array.isRequired,
  bookedTimeSlotIds: PropTypes.array.isRequired,
  timelineStartHour: PropTypes.number.isRequired,
  divisionBookableDays: PropTypes.number.isRequired,
  timelineEndHour: PropTypes.number.isRequired,
  timelineStartMinutes: PropTypes.number.isRequired,
  onSlotClick: PropTypes.func.isRequired
};

export default observer(SlotsAndTimeline);
