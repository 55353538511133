import React, { Component } from 'react';
import { Container, CssBaseline } from '@material-ui/core';

class NotAuthorizedScreenWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  renderContent() {
    return this.props.children;
  }

  render() {
    return (
      <>
        <CssBaseline/>
        <Container>
          {this.renderContent()}
        </Container>
      </>
    );
  }
}

export default NotAuthorizedScreenWrapper;
