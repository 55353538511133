import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TextField from '@components/atoms/TextField';
import Box from '@components/atoms/Box';
import Typography from '@components/atoms/Typography';
import Form from '@components/organisms/Form';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Div100vh from 'react-div-100vh';
import { withTranslation, Trans } from 'react-i18next';
import {
  NewPasswordBody,
  NewPasswordContainer,
  NewPasswordStep,
  NewPasswordSteps,
  NewPasswordTextFields,
  NewPasswordTopBar,
  StyledButton
} from './styles';
import { BOOKING_URL } from '@helpers/urls';
import { navigateTo } from '@helpers/navigation/navigation';

class NewPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      authorizing: false,
      accountName: '',
      logo: '',
      primaryColor: ''
    };

    this.handleNewPassword = this.handleNewPassword.bind(this);
  }

  handleNewPassword(fieldsValues) {
    const { t } = this.props

    if (fieldsValues.password.value.trim().length < 6)
      return this.setState({ errors: { password: [t('newPasswordScreen:not_valid_password')] }});

    const urlParams = new URLSearchParams(window.location.search);

    this.props.userStore.assignToken({
      'access-token': urlParams.get('access-token'),
      client: urlParams.get('client'),
      uid: urlParams.get('uid')
    });

    this.props.userStore.newPassword({
      password: fieldsValues.password.value,
      passwordConfirmation: fieldsValues.password.value
    }).then(() => {
      navigateTo([BOOKING_URL]);
    });
  }

  componentDidMount() {
    this.props.accountStore.fetchAccount();
  }

  renderNewPasswordForm() {
    const { t } = this.props;

    return (
      <Form errors={this.props.userStore.error || this.state.errors}>
        <NewPasswordTextFields>
          <TextField
            name='TextField'
            key='password'
            type='password'
            required
            label={t('newPasswordScreen:passwordFieldLabel')}
            margin='normal'
            fullWidth
          />
        </NewPasswordTextFields>
        <StyledButton
          data-cy='newPasswordButton'
          name='Button'
          loading={this.props.userStore.signingIn}
          fullWidth
          variant='contained'
          onClick={this.handleNewPassword}
          key='accept'
        >
          <Trans>newPasswordScreen:newPassword</Trans>
          <ArrowForward/>
        </StyledButton>
      </Form>
    );
  }

  renderNewPasswordBody() {
    return (
      <NewPasswordBody>
        <Grid item xs={12}>
          <NewPasswordTopBar>
            <img
              src={this.props.accountStore.accountLogo}
              alt={this.props.accountStore.accountName}
              width='48px'
              height='47px'
            />
            <NewPasswordSteps>
              <NewPasswordStep isActive/>
              <NewPasswordStep/>
            </NewPasswordSteps>
          </NewPasswordTopBar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <h1><Trans>newPasswordScreen:title</Trans></h1>
          <NewPasswordContainer>
            {this.renderNewPasswordForm()}
          </NewPasswordContainer>
        </Grid>
      </NewPasswordBody>
    );
  }

  render() {
    return (
      <>
        <CssBaseline/>
        <Div100vh>
          <NewPasswordContainer>
            {this.renderNewPasswordBody()}
            <Box py={2}>
              <Typography variant='body1' data-cy='infiniteLoopC'>© 2020 Infinite Loop</Typography>
            </Box>
          </NewPasswordContainer>
        </Div100vh>
      </>
    );
  }
}

const newPasswordScreenWithoutI18n = inject('userStore', 'accountStore')(observer(NewPasswordScreen));
export { newPasswordScreenWithoutI18n };
export default withTranslation()(newPasswordScreenWithoutI18n);
