import React, { Component } from 'react';
import DialogMaterialUi from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MakeBookingDialogFiller from '@components/molecules/MakeBookingDialogFiller';
import { inject, observer } from 'mobx-react';
import DialogTheme from './theme.jsx';

class MakeBookingDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.addOpenOnClickPropForChildren = this.addOpenOnClickPropForChildren.bind(this);
    this.getConfigurationProps = this.getConfigurationProps.bind(this);
    this.childrenToRender = this.addOpenOnClickPropForChildren();
  }

  handleClose() {
    this.props.makeBookingDialogStore.close();
  }

  addOpenOnClickPropForChildren() {
    return React.Children.map(this.props.children,
      (child) => React.cloneElement(child, { onClick: this.handleOpen }));
  }

  getConfigurationProps() {
    const
      {
        makeBookingDialogStore,
        bookingsStore,
        notificationsStore,
        ...configurationProps
      } = this.props;
    return configurationProps;
  }

  renderConfirmReservation() {
    const {
      date, startHour, endHour, startMinutes, endMinutes,
      slotId, numberOfOccupiedPlaces, numberOfAllPlaces,
    } = this.props.makeBookingDialogStore.data;
    return (
      <MakeBookingDialogFiller
        bookingCreating={this.props.bookingsStore.creating}
        notificationCreating={this.props.notificationsStore.creating}
        date={date}
        startHour={startHour}
        startMinutes={startMinutes}
        endHour={endHour}
        endMinutes={endMinutes}
        slotId={slotId}
        numberOfAllPlaces={numberOfAllPlaces}
        numberOfOccupiedPlaces={numberOfOccupiedPlaces}
        onAgreeClick={this.props.makeBookingDialogStore.onAgreeClick}
        onCloseClick={this.handleClose}
      />
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...DialogTheme })}>
        {this.childrenToRender}
        <DialogMaterialUi
          disableBackdropClick
          {...this.getConfigurationProps()}
          open={this.props.makeBookingDialogStore.opened}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogContent data-cy="makeBookingDialog">
            {this.renderConfirmReservation()}
          </DialogContent>
        </DialogMaterialUi>
      </MuiThemeProvider>
    );
  }
}

export default inject('makeBookingDialogStore', 'bookingsStore', 'notificationsStore')(
  observer(MakeBookingDialog),
);
