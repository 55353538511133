import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const StyledTitle = withTheme(styled('div')`
  margin-top: 64px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 24px;
  }
`);

export const StyledButton = withTheme(styled('div')`
  position: absolute;
  left: 20px;
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0 40px 0;
  color: ${({ theme }) => theme.palette.grey['500']};
  display: flex;
  justify-content: space-between;

  button {
    width: calc(50% - 8px);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: right;
    position: static;
    left: auto;
    bottom: auto;
    width: 100%;
    padding: 100px 0 20px 0;
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
      min-width: 196px;
      margin-left: 16px;
    }
  }
`);
