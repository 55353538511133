import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '@/translations/en/index';
import deTranslations from '@/translations/de/index';
import plTranslations from '@/translations/pl/index';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: enTranslations,
  de: deTranslations,
  pl: plTranslations,
};

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
