import React from 'react';
import CloseButton from '@components/atoms/buttons/CloseButton';
import PropTypes from 'prop-types';
import Typography from '@components/atoms/Typography';
import { StyledCloseDialogButton } from './styles';

const ConfirmReservationHeader = ({ onCloseClick, loading, title }) => (
  <>
    <StyledCloseDialogButton>
      <CloseButton
        color="inherit"
        onClick={onCloseClick}
        disabled={loading}
        data-cy="closeDialogButton"
      />
    </StyledCloseDialogButton>
    <Typography variant="h3" gutterBottom data-cy="confirmReservationDialogTitle">
      {title}
    </Typography>
  </>
);

ConfirmReservationHeader.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmReservationHeader;
