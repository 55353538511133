import React from 'react';
import { LoaderBody, Spinner } from './styles';
import { observer } from 'mobx-react';

const Loader = ({ ...defaultProps }) => (
  <LoaderBody {...defaultProps}>
    <Spinner />
  </LoaderBody>
);

export default observer(Loader);
