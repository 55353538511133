import { account} from '@/helpers/endpoints';
import { FETCHED, FETCHING } from '@helpers/constants';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { getUrlParam } from '@helpers/urlParams';
import { userDivisionStore } from '@stores/stores';
import ApiStore from './ApiStore';

class AccountStore extends ApiStore {
  constructor(errorsStore) {
    super(errorsStore);
    this.initFields();
    makeObservable(this, {
      accountLogo: observable,
      divisions: observable,
      currentDivisionId: observable,
      futureVisibleDays: observable,
      bookableDaysInAdvance: observable,
      currentDivisionSlug: observable,
      accountName: observable,
      userDivisions: observable,
      currentDivision: computed,
      assignDivision: action.bound,
      startHour: computed,
      endHour: computed,
      startMinutes: computed,
      endMinutes: computed,
      colorThemeId: computed
    });
    this.fetchAccount = this.fetchAccount.bind(this);
  }

  initFields() {
    runInAction(() => {
      this.currentDivisionId = '';
      this.currentDivisionSlug = '';
      this.divisions = [];
      this.userDivisions = [];
      this.accountName = '';
      this.accountLogo = '';
      this.futureVisibleDays = '';
      this.bookableDaysInAdvance = 1;
    });
  }

  get currentDivision() {
    return this.divisions.find(
      (division) => division.id === this.currentDivisionId
    ) || {};
  }

  fetchAccount() {
    this.changedState(FETCHING);
    return account.getAccount()
      .then((response) => {
        this.account = this.getDataFromResponse(response);
        this.accountName = this.account.attributes.name;
        this.accountLogo = this.account.attributes.logo;
        this.userDivisions = userDivisionStore.userDivisions.map((division) => division.divisionId);
        this.divisions = this.getIncludedFromResponse(response)
          .map((division) => division.attributes)
          .filter((division) => this.userDivisions.indexOf(division.id) > -1);
        if (!getUrlParam('division')) {
          this.assignDivision(this.divisions[0]);
        }
        this.changedState(FETCHED);
      });
  }

  get startHour() {
    return new Date(this.currentDivision.openingHour).getHours();
  }

  get startMinutes() {
    return new Date(this.currentDivision.openingHour).getMinutes();
  }

  get endHour() {
    const endHour = new Date(this.currentDivision.closingHour).getHours();
    if (this.startHour === endHour) return 24;
    return endHour;
  }

  get endMinutes() {
    return new Date(this.currentDivision.closingHour).getMinutes();
  }

  get colorThemeId() {
    return this.account?.attributes?.colorThemeId;
  }

  assignDivision(division) {
    if (division === undefined) return;
    this.currentDivisionId = division.id;
    this.currentDivisionSlug = division.slug;
    this.futureVisibleDays = division.futureVisibleDays;
    this.bookableDaysInAdvance = division.bookableDaysInAdvance;
  }
}

export default AccountStore;
