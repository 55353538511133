import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const StyledButton = withTheme(styled('div')`
  position: absolute;
  left: 20px;
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0 40px 0;

  button {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: center;
    position: static;
    left: auto;
    bottom: auto;
    width: 100%;
    padding: 60px 0 20px 0;

    button {
      width: auto;
      min-width: 196px;
    }
  }
`);
