import { MATCH_USERNAME_IN_EMAIL_REGEX } from '@helpers/regexes';

function addZeroBefore(number) {
  return (number < 10 ? '0' : '') + number;
}

/**
 * Returns date as a string in specified format.
 * if yearFirst is true it will be: year <separator> month <separator> day
 * otherwise: day <separator> month <separator> year
 * if addZero is true date would look like 01.09.1997 instead of 1.9.1997
 * @param date
 * @param separator
 * @param yearFirst
 * @param addZero
 * @returns {string}
 */
function formatDate(date, yearFirst, separator = '-', addZero = true) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (addZero) {
    month = addZeroBefore(month);
    day = addZeroBefore(day);
  }
  if (yearFirst) {
    return year + separator + month + separator + day;
  }
  return day + separator + month + separator + year;
}

function formatTime(hours, minutes, separator = ':', addZero = true) {
  if (addZero) {
    minutes = addZeroBefore(minutes);
    hours = addZeroBefore(hours);
  }
  return hours + separator + minutes;
}

/**
 * Formats minutes.
 *
 * Example: 4h 15min
 * @returns {string}
 * @param minutes
 * @param strAfterMin
 * @param strAfterHours
 * @param separator
 * @param addZero
 */
function formatMinutes(minutes, strAfterMin, strAfterHours, separator = ' ', addZero = false) {
  const hours = (minutes / 60);
  const rhours = Math.floor(hours);
  const minutes_ = (hours - rhours) * 60;
  const rminutes = Math.round(minutes_);
  if (addZero) {
    return addZeroBefore(rhours) + strAfterHours + separator + addZeroBefore(rminutes)
      + strAfterMin;
  }
  return rhours + strAfterHours + separator + rminutes + strAfterMin;
}

/**
 * Returns string with specified number of first letters of words from given string.
 * Example:
 * firstLetters (words='Ala Ma Kota', numberOfFirstLetters=2)
 *
 * output would be:
 * 'AM'
 * @returns {string}
 * @param words
 * @param numberOfFirstLetters
 */
function firstLetters(words, numberOfFirstLetters = 2) {
  if (words) {
    const firstLetters = words.match(/\b(\w)/g);
    let output = '';
    for (let i = 0; i < numberOfFirstLetters && i < firstLetters.length; i++) {
      output += firstLetters[i];
    }
    return output;
  }
  return '';
}

function getUsernameFromEmail(email) {
  return email.match(MATCH_USERNAME_IN_EMAIL_REGEX);
}

export {
  addZeroBefore, formatMinutes, formatDate, firstLetters, getUsernameFromEmail, formatTime
};
