import { Button as MaterialButton } from '@material-ui/core';
import CircularProgress from '@components/atoms/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ButtonTheme from './theme';

const Button = observer((props) => {
  let { loading, children, startIcon, ...defaultProps } = props;

  if (loading) {
    children = <CircularProgress size={24} color='inherit' />;
    startIcon = undefined;
  }
  return (
    <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...ButtonTheme(theme) })}>
      <MaterialButton disabled={Boolean(loading)} startIcon={startIcon} {...defaultProps}>
        {children}
      </MaterialButton>
    </MuiThemeProvider>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool
};

export default Button;
