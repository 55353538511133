import commonTranslations from '@helpers/commonTranslations/en/common.json';
import atomsEnTranslations from '@components/atoms/translations/en/index';
import moleculesEnTranslations from '@components/molecules/translations/en/index';
import organismsEnTranslations from '@components/organisms/translations/en/index';
import screensEnTranslations from '@components/screens/translations/en/index';

const enTranslations = {
  common: commonTranslations,
  ...atomsEnTranslations,
  ...moleculesEnTranslations,
  ...organismsEnTranslations,
  ...screensEnTranslations,
};

export default enTranslations;
