const TextFieldTheme = (theme) => ({
  overrides: {
    MuiInput: { root: { fontWeight: 700 } },
    MuiInputLabel: {
      root: {
        color: '#66718A',
        fontSize: 14,
        fontWeight: 400,
        '&$focused': {
          color: '#3D3D3D',
          fontWeight: 400
        },
        '&$error': {
          fontWeight: 400,
          color: '#FD6A6A'
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          borderColor: theme.palette.primary,
          fontWeight: 600
        }
      },
      outlined: { transform: 'translate(20px, 17px) scale(1)' }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        fontWeight: 700,
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': { borderColor: '#858DA1' },
        '&$focused:not($disabled):not($error) $notchedOutline': { borderColor: theme.palette.primary }
      },
      input: {
        fontSize: 12,
        padding: '17px 20px',
        '$error &': { color: '#FD6A6A' }
      },
      notchedOutline: {
        borderColor: '#D2DDEE',
        borderWidth: 2,
        transition: 'all .2s ease-in-out'
      }
    },
    MuiTextField: { root: { borderColor: '#D2DDEE' } }
  }
});

export default TextFieldTheme;
