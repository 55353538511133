import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const TypographyTheme = {
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Jost, Arial',
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    color: '#0A0B0E',
    h1: {
      fontSize: '36px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '36px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '48px',
        lineHeight: '64px',
      },
    },
    h2: {
      fontSize: '30px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '36px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '36px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: '36px',
      },
    },
    h4: {
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '32px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    h5: {
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '17px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '28px',
      },
    },
    h6: {
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '19px',
      color: '#000000',
      [breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      [breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.01em',
      [breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
  },
};

export default TypographyTheme;
