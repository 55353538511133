import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import React from 'react';
import App from '@/App';
import { Router } from 'react-router-dom';
import history from '@/history';
import * as stores from '@/stores/stores';
import { Provider } from 'mobx-react';
import interceptorsSetup from '@/helpers/interceptors';
import '@/i18n';
import * as Sentry from '@sentry/react';
import sentryEndpoint from '@helpers/sentryHelper';
import ReactGA from 'react-ga';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: sentryEndpoint });
}
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-VZJ585F0QC');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

document.addEventListener('DOMContentLoaded', () => {
  interceptorsSetup(stores);

  ReactDOM.render(
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    document.body.appendChild(document.getElementById('app'))
  );
});
