import { action, makeObservable, observable, runInAction } from 'mobx';
import ApiStore from '@stores/ApiStore';
import {
  CREATED,
  CREATING,
  UNAUTHORIZED,
  DELETED,
  DELETING,
  ERROR,
  FETCHED,
  FETCHING,
  SUCCESS
} from '@helpers/constants';
import { bookings as bookingsEndpoint } from '@helpers/endpoints';
import { stringToDate } from '@helpers/dates/dates';
import { addSeconds } from '@helpers/time/time';

class BookingsStore extends ApiStore {
  constructor(errorsStore) {
    super(errorsStore);
    this.initFields();
    makeObservable(this, {
      spotsAmount: observable,
      bookings: observable,
      addBooking: action.bound,
      fetchBookingsForDate: action.bound,
      cancelBooking: action.bound,
      setBookings: action.bound,
      setTimeSlotsIds: action.bound,
      setSpotsAmount: action.bound
    });
    this.errorsStore = errorsStore;
    this.assignDateToStore = this.assignDateToStore.bind(this);
  }

  assignDateToStore(dateString, divisionId) {
    this.currentDate = dateString;
    this.divisionId = divisionId;
  }

  setBookings(newBookings) {
    this.bookings = newBookings;
  }

  setTimeSlotsIds(newTimeSlotsIds) {
    this.timeSlotIds = newTimeSlotsIds;
  }

  setSpotsAmount(newSpotsAmount) {
    this.spotsAmount = newSpotsAmount;
  }

  initFields() {
    runInAction(() => {
      this.spotsAmount = 1;
      this.bookings = [];
      this.currentDate = '';
      this.divisionId = '';
      this.timeSlotIds = [];
      this.data = new Map();
    });
  }

  fetchBookingsForDate(dateString, divisionId) {
    if (this.fetching) return;
    this.changedState(FETCHING);
    this.assignDateToStore(dateString, divisionId);
    return bookingsEndpoint.all(dateString)
      .then((response) => {
        this.setBookings(this.getDataFromResponse(response));
        this.setTimeSlotsIds(this.getIncludedFromResponse(response)
          .map((timeSlot) => timeSlot.id));
        this.changedState(FETCHED);
      })
      .catch((error) => {
        this.changedState(ERROR, error);
        this.handleServerError(error);
      });
  }

  addBooking(slotId) {
    return this.addNewBooking(slotId)
      .then(({
        status,
        response
      }) => this.bookingManipulation(status, response));
  }

  addNewBooking(timeSlotId) {
    if (this.creating) return;
    this.changedState(CREATING);
    return bookingsEndpoint.add(timeSlotId, this.divisionId, this.spotsAmount)
      .then((response) => {
        this.changedState(CREATED);
        this.setSpotsAmount(1);
        return {
          status: SUCCESS,
          response
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.changedState(UNAUTHORIZED, error);
          this.handleServerError(error);
          return { status: UNAUTHORIZED };
        }
        this.changedState(ERROR, error);
        this.handleServerError(error);
        return { status: ERROR };
      });
  }

  cancelBooking(bookingId) {
    return this.handleCancelBooking(bookingId)
      .then(({
        status,
        response
      }) => this.bookingManipulation(status, response));
  }

  handleCancelBooking(bookingId) {
    if (this.deleting) return;
    this.changedState(DELETING);
    return bookingsEndpoint.delete(bookingId)
      .then((response) => {
        this.changedState(DELETED);
        return {
          status: SUCCESS,
          response
        };
      })
      .catch((error) => {
        this.changedState(ERROR, error);
        this.handleServerError(error);
        return { status: ERROR };
      });
  }

  bookingManipulation(status, response) {
    if (status === SUCCESS) {
      return {
        status,
        data: this.parseDataForComponent(this.getDataFromResponse(response))
      };
    }

    return { status };
  }

  parseDataForComponent(data) {
    const object = data.attributes;
    const startedAt = stringToDate(object.startedAt);
    const endedAt = addSeconds(stringToDate(object.endedAt), 1);
    return {
      startHour: startedAt.getHours(),
      startMinutes: startedAt.getMinutes(),
      endHour: endedAt.getHours(),
      endMinutes: endedAt.getMinutes(),
      date: stringToDate(object.date)
    };
  }
}

export default BookingsStore;
