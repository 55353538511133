import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@components/atoms/icons/NavigateNextIcon';
import { observer } from 'mobx-react';

const NavigateNextButton = (props) => (
  <IconButton {...props}>
    <NavigateNextIcon />
  </IconButton>
);

export default observer(NavigateNextButton);
