const globalThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1800
    }
  },

  typography: {
    htmlFontSize: 16,
    fontFamily: 'Jost, Arial',
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    color: '#000000',

    button: {
      fontSize: 14,
      letterSpacing: '0.01em',
      fontWeight: 800,
      lineHeight: '22px'
    }
  },

  status: { danger: 'orange' },

  overrides: {
    MuiCssBaseline: { '@global': { '@font-face': ['Inter'] } },
    MuiRadio: {
      root: {
        color: '#66718A',
        '&$checked': { color: '#9bda77' }
      },
      colorSecondary: {
        color: '#66718A',
        '&$checked': {
          color: '#0064FA',
          '& + span': { color: '#9bda77' }
        }
      }
    },
    MuiFormControlLabel: {
      root: { color: '#66718A' },
      label: { color: '#66718A' }
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        color: '#66718A',
        padding: '0 20px',
        fontWeight: '600',
        fontSize: 12,
        '&$error': {
          color: '#FD6A6A',
          fontWeight: '600'
        }
      }
    },
    MuiSnackbar: { root: {} },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 2px #DDE5F0',
        color: '#66718A',
        padding: '6px 20px'
      },
      action: {
        color: '#9bda77',
        marginRight: '0',
        cursor: 'pointer'
      }
    },
    MuiSelect: { root: { color: 'red' } }
  }
};

export default globalThemeOptions;
