import cancelBookingButtonTranslations
  from '@components/atoms/buttons/CancelBookingButton/translations/en.json';
import slotTranslations from '@components/atoms/Slot/translations/en.json';

const atomsEnTranslations = {
  cancelBookingButton: cancelBookingButtonTranslations,
  slot: slotTranslations,
};

export default atomsEnTranslations;
