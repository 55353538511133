import AccountStore from '@stores/AccountStore';
import ResultDialogStore from '@stores/DialogsStores/ResultDialogStore';
import MakeBookingDialogStore from '@stores/DialogsStores/MakeBookingDialogStore';
import TimeSlotsStore from '@stores/TimeSlotsAndBookingsStores/TimeSlotsStore';
import BookingsStore from '@stores/TimeSlotsAndBookingsStores/BookingsStore';
import CancelBookingDialogStore from '@stores/DialogsStores/CancelBookingDialogStore';
import CurrentlyChosenDateStore from '@stores/CurrentlyChosenDateStore';
import ColorThemeStore from '@stores/ColorThemeStore';
import UserDivisionStore from '@stores/UserDivisionStore';
import NotificationsStore from './NotificationsStore';
import UserStore from './UserStore';
import ErrorsStore from './ErrorsStore';

const errorsStore = new ErrorsStore();
const accountStore = new AccountStore(errorsStore);
const userStore = new UserStore(errorsStore);
const timeSlotsStore = new TimeSlotsStore(errorsStore);
const bookingsStore = new BookingsStore(errorsStore);
const currentlyChosenDateStore = new CurrentlyChosenDateStore();
const notificationsStore = new NotificationsStore(errorsStore);
const resultDialogStore = new ResultDialogStore();
const makeBookingDialogStore = new MakeBookingDialogStore();
const cancelBookingDialogStore = new CancelBookingDialogStore();
const colorThemeStore = new ColorThemeStore();
const userDivisionStore = new UserDivisionStore();

export {
  errorsStore,
  userStore,
  accountStore,
  bookingsStore,
  timeSlotsStore,
  currentlyChosenDateStore,
  notificationsStore,
  resultDialogStore,
  makeBookingDialogStore,
  cancelBookingDialogStore,
  colorThemeStore,
  userDivisionStore
};
