const sharedColors = {
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  primary: {
    main: '#000000',
    25: '#F0F6FF',
    150: '#9bda77',
    350: '#9bda77',
    400: '#9bda77'
  },
  secondary: { main: '#A7A7A7' },
  grey: {
    main: '#a7a7a7',
    25: '#F6F7F8',
    50: '#EBECEF',
    200: '#ededed',
    400: '#c7c7c7',
    500: '#a7a7a7',
    600: '#676767',
    700: '#3d3d3d',
    900: '#000000'
  },
  error: { main: '#FD6A6A' },
  text: {
    primary: '#0A0B0E',
    secondary: '#A7A7A7'
  },
  success: { main: '#4BBF82' },
  warning: { main: '#FFA841' }
};

export default sharedColors;
