import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const StyledCloseDialogButton = withTheme(styled('div')`
  text-align: right;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 24px;
  margin-right: -12px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 0;
  }
`);
