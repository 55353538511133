import React, { Component } from 'react';
import { Container, CssBaseline } from '@material-ui/core';

class AnonymousTemplate extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <Container>
          {this.props.children}
        </Container>
      </>
    );
  }
}

export default AnonymousTemplate;
