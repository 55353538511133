import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addZeroBefore } from '@/helpers/formatting/formatting';
import PeopleIcon from '@components/atoms/icons/PeopleIcon';
import ScheduleIcon from '@components/atoms/icons/ScheduleIcon';
import CheckIcon from '@components/atoms/icons/CheckIcon';
import Box from '@components/atoms/Box';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { INITIAL_DIALOG_VARIANTS } from '@helpers/constants';
import { observer } from 'mobx-react';
import { userStore } from '@stores/stores';
import { StyledSlot, StyledSlotContent, StyledTime } from './styles';

class Slot extends Component {
  constructor(props) {
    super(props);
    this.renderPlacesInformation = this.renderPlacesInformation.bind(this);
    this.isOccupied = this.isOccupied.bind(this);
    this.getDialogVariant = this.getDialogVariant.bind(this);
    this.bookingsUnavailableAndNotBooked = this.bookingsUnavailableAndNotBooked.bind(this);
    this.slotNumbers = this.slotNumbers.bind(this);
    this.slotInformations = this.slotInformations.bind(this);
  }

  getDialogVariant(booked, bookable, numberOfAllPlaces, numberOfOccupiedPlaces) {
    if (booked || !bookable || this.bookingsUnavailableAndNotBooked(booked)) return;
    if (this.isOccupied(numberOfAllPlaces,
      numberOfOccupiedPlaces)) {
      return INITIAL_DIALOG_VARIANTS.NOTIFY_ME;
    }
    return INITIAL_DIALOG_VARIANTS.MAKE_BOOKING;
  }

  isOccupied(numberOfAllPlaces, numberOfOccupiedPlaces) {
    return numberOfAllPlaces === numberOfOccupiedPlaces;
  }

  bookingsUnavailableAndNotBooked(booked) {
    return !this.props.bookingsAvailable && !booked;
  }

  slotNumbers(numberOfOccupiedPlaces, numberOfAllPlaces, booked, users, bookable) {
    if (this.props.bookingsAvailable || booked) {
      return (
        <>
          <Box
            display='flex'
            alignItems='center'
            style={{ transform: (!booked && bookable) ? 'translateY(-25px)' : null }}
            mr={1}
          >
            <PeopleIcon />
            {numberOfOccupiedPlaces}
            /
            {numberOfAllPlaces}
            <Box ml={2} display='flex' alignItems='center'>
              {this.slotInformations(booked, users)}
            </Box>
          </Box>
        </>
      );
    }
  }

  slotInformations(booked, users) {
    let string = '';
    if (booked) {
      users.map((user, index) => {
        if (user.spots > 1) string += `${user.spots}x `;
        if (user.id === userStore.id) {
          string += i18next.t("slot:you");
        } else {
          string += user.firstName;
        }
        if (index < users.length - 1) string += ', ';
      });
    }
    return string;
  }

  renderPlacesInformation(numberOfAllPlaces, numberOfOccupiedPlaces, booked, bookable) {
    if (this.bookingsUnavailableAndNotBooked(booked)) {
      return (
        <span id='slotCannotBookMore'>
          <Trans>slot:cannotBookMore</Trans>
        </span>
      );
    }
    if (booked) {
      return (
        <>
          <Box
            id='slotBookedInformation'
            display='flex'
            alignItems='center'
            mr={1}
            data-cy='slotBookedInformation'
          >
            <CheckIcon />
          </Box>
          <Trans>slot:bookedInformation</Trans>
        </>
      );
    }
    if (!bookable) {
      return (
        <span id='slotCannotBeBookedYet'>
          <Trans>slot:cannotBookYet</Trans>
        </span>
      );
    }
  }

  render() {
    const {
      startHour,
      startMinutes,
      endHour,
      endMinutes,
      height,
      booked,
      numberOfAllPlaces,
      users,
      numberOfOccupiedPlaces,
      bookable
    } = this.props;
    const {
      onClick,
      ...dialogProps
    } = this.props;
    return (
      <StyledSlot
        data-cy='slot'
        height={height}
        bookable={bookable}
        cantBookMore={this.bookingsUnavailableAndNotBooked}
        isOccupied={() => this.isOccupied(numberOfAllPlaces, numberOfOccupiedPlaces)}
        booked={booked}
        onClick={() => onClick(this.getDialogVariant(booked, bookable, numberOfAllPlaces,
          numberOfOccupiedPlaces), dialogProps)}
      >
        <StyledSlotContent
          bookable={bookable}
          cantBookMore={this.bookingsUnavailableAndNotBooked}
          isOccupied={() => this.isOccupied(numberOfAllPlaces, numberOfOccupiedPlaces)}
          booked={booked}
        >
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              {this.renderPlacesInformation(numberOfAllPlaces,
                numberOfOccupiedPlaces, booked, bookable)}
            </Box>
            <Box display='flex' alignItems='center'>
              <Box mr={1} display='flex' alignItems='center'>
                <ScheduleIcon />
              </Box>
              <StyledTime>
                {startHour}
                :
                {addZeroBefore(startMinutes)}
                {' - '}
                {addZeroBefore(endHour)}
                :
                {addZeroBefore(endMinutes)}
              </StyledTime>
            </Box>
          </Box>
          {this.slotNumbers(numberOfOccupiedPlaces, numberOfAllPlaces, booked, users, bookable)}
        </StyledSlotContent>
      </StyledSlot>
    );
  }
}

Slot.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.string.isRequired,
  numberOfAllPlaces: PropTypes.number.isRequired,
  booked: PropTypes.bool.isRequired,
  bookable: PropTypes.bool.isRequired,
  numberOfOccupiedPlaces: PropTypes.number.isRequired,
  startHour: PropTypes.number.isRequired,
  startMinutes: PropTypes.number.isRequired,
  endHour: PropTypes.number.isRequired,
  endMinutes: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  bookingsAvailable: PropTypes.bool.isRequired
};

export default observer(Slot);
