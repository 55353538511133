import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoginHeader from '@components/organisms/LoginHeader';
import TextField from '@components/atoms/TextField';
import Box from '@components/atoms/Box';
import Typography from '@components/atoms/Typography';
import Form from '@components/organisms/Form';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { navigateTo } from '@helpers/navigation/navigation';
import { CONFIRM_CODE_URL, BOOKING_URL } from '@helpers/urls';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Div100vh from 'react-div-100vh';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  LoginBody,
  LoginContainer,
  LoginFormContainer,
  LoginStep,
  LoginSteps,
  LoginTextFields,
  LoginTopBar,
  StyledButton
} from './styles';
import { LoginFooter } from '../ConfirmCodeScreen/styles';
import { RESET_PASSWORD_URL } from '@helpers/urls';

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      authorizing: false,
      accountName: '',
      logo: '',
      primaryColor: '',
      usePasswordFlow: true
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.changeSignInFlow = this.changeSignInFlow.bind(this);
  }

  changeSignInFlow() {
    this.setState({ usePasswordFlow: !this.state.usePasswordFlow });
  }

  handleLogin(fieldsValues) {
    const { usePasswordFlow } = this.state;

    let params = {
      email: fieldsValues.email.value
    };

    if (usePasswordFlow) {
      params.password = fieldsValues.password.value;
      params.passwordFlow = this.state.usePasswordFlow;
    }

    this.props.userStore.signIn(
      params,
      usePasswordFlow
    ).then(() => {
      navigateTo([usePasswordFlow ? BOOKING_URL : CONFIRM_CODE_URL]);
    })
  }

  componentDidMount() {
    this.props.accountStore.fetchAccount();
    this.props.userStore.validateToken().then(() => {
      if (this.props.userStore.validated)
        navigateTo([BOOKING_URL])
    })
  }

  renderLoginForm() {
    const { usePasswordFlow } = this.state;
    const { t } = this.props;

    return (
      <Form errors={this.props.userStore.error}>
        <LoginTextFields>
          <TextField
            name='TextField'
            key='email'
            label={t('loginScreen:emailFieldLabel')}
            margin='normal'
            fullWidth
          />
          {usePasswordFlow &&
            <TextField
              name='TextField'
              key='password'
              type='password'
              label={t('loginScreen:passwordFieldLabel')}
              margin='password'
              fullWidth
            />
          }
        </LoginTextFields>
        <StyledButton
          data-cy='loginButton'
          name='Button'
          loading={this.props.userStore.signingIn}
          fullWidth
          variant='contained'
          onClick={this.handleLogin}
          key='accept'
        >
          {t(usePasswordFlow ? 'loginScreen:signInButton' : 'loginScreen:loginButtonTitle')}
          <ArrowForward/>
        </StyledButton>
      </Form>
    );
  }

  renderLoginBody() {
    return (
      <LoginBody>
        <Grid item xs={12}>
          <LoginTopBar>
            <img
              src={this.props.accountStore.accountLogo}
              alt={this.props.accountStore.accountName}
              width='48px'
              height='47px'
            />
            <LoginSteps>
              <LoginStep isActive/>
              <LoginStep/>
            </LoginSteps>
          </LoginTopBar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <LoginHeader accountName={this.props.accountStore.accountName}/>
          <LoginFormContainer>
            {this.renderLoginForm()}
          </LoginFormContainer>
        </Grid>
      </LoginBody>
    );
  }

  renderFooter() {
    const { t } = this.props;
    const { usePasswordFlow } = this.state;

    return (
      <LoginFooter>
        <Link onClick={() => this.changeSignInFlow()}>
          <Box display='flex' alignItems='center'>
            {t(usePasswordFlow ? 'loginScreen:useCode' : 'loginScreen:usePassword')}
          </Box>
        </Link>
        <Link to={RESET_PASSWORD_URL}>
          <Box display='flex' alignItems='center'>
            {t('loginScreen:resetPassword')}
          </Box>
        </Link>
      </LoginFooter>
    );
  }

  render() {
    return (
      <>
        <CssBaseline/>
        <Div100vh>
          <LoginContainer>
            {this.renderLoginBody()}
            {this.renderFooter()}
            <Box py={2}>
              <Typography variant='body1' data-cy='infiniteLoopC'>© 2020 Infinite Loop</Typography>
            </Box>
          </LoginContainer>
        </Div100vh>
      </>
    );
  }
}

const loginScreenWithoutI18n = inject('userStore', 'accountStore')(observer(LoginScreen));
export { loginScreenWithoutI18n };
export default withTranslation()(loginScreenWithoutI18n);
