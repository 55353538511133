import React from 'react';
import { Box as MaterialBox } from '@material-ui/core';
import { observer } from 'mobx-react';

const Box = (props) => {
  const { children, ...defaultProps } = props;
  return (
    <MaterialBox {...defaultProps}>
      {children}
    </MaterialBox>
  );
};
export default observer(Box);
