import React, { Component } from 'react';
import DialogMaterialUi from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CancelBookingDialogFiller from '@components/molecules/CancelBookingDialogFiller';
import { inject, observer } from 'mobx-react';
import DialogTheme from './theme.jsx';

class CancelBookingDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.addOpenOnClickPropForChildren = this.addOpenOnClickPropForChildren.bind(this);
    this.getConfigurationProps = this.getConfigurationProps.bind(this);
    this.childrenToRender = this.addOpenOnClickPropForChildren();
  }

  handleClose() {
    this.props.cancelBookingDialogStore.close();
  }

  handleOpen() {
    this.props.cancelBookingDialogStore.open();
  }

  addOpenOnClickPropForChildren() {
    return React.Children.map(this.props.children,
      (child) => React.cloneElement(child, { onClick: this.handleOpen }));
  }

  getConfigurationProps() {
    const
      {
        cancelBookingDialogStore,
        bookingsStore,
        ...configurationProps
      } = this.props;
    return configurationProps;
  }

  renderContent() {
    const {
      date, startHour, endHour, startMinutes, endMinutes,
    } = this.props.cancelBookingDialogStore.data;
    return (
      <CancelBookingDialogFiller
        bookingIsBeingCancelled={this.props.bookingsStore.deleting}
        date={date}
        startHour={startHour}
        startMinutes={startMinutes}
        endHour={endHour}
        endMinutes={endMinutes}
        onCancelClick={this.props.cancelBookingDialogStore.onAgreeClick}
        onCloseClick={this.handleClose}
      />
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...DialogTheme })}>
        {this.childrenToRender}
        <DialogMaterialUi
          {...this.getConfigurationProps()}
          disableBackdropClick
          open={this.props.cancelBookingDialogStore.opened}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogContent data-cy="cancelBookingDialog">
            {this.renderContent()}
          </DialogContent>
        </DialogMaterialUi>
      </MuiThemeProvider>
    );
  }
}

export default inject('cancelBookingDialogStore', 'bookingsStore')(observer(CancelBookingDialog));
