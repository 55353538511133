import React from 'react';
import PropTypes from 'prop-types';
import { Typography as MaterialTypography } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import TypographyTheme from './theme';

const Typography = (props) => {
  const { children, ...defaultProps } = props;

  return (
    <MuiThemeProvider theme={(theme) => createMuiTheme({ ...theme, ...TypographyTheme })}>
      <MaterialTypography {...defaultProps}>
        {children}
      </MaterialTypography>
    </MuiThemeProvider>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
};

export default observer(Typography);
