import { action, makeObservable } from 'mobx';
import { CREATED, CREATING, ERROR } from '@helpers/constants';
import { notifications } from '@helpers/endpoints';
import ApiStore from './ApiStore';

class NotificationsStore extends ApiStore {
  constructor(errorsStore) {
    super(errorsStore);
    makeObservable(this, { addNotification: action.bound });
  }

  addNotification(timeSlotId) {
    this.changedState(CREATING);
    return notifications.add(timeSlotId)
      .then(() => {
        this.changedState(CREATED);
      })
      .catch((error) => {
        this.changedState(ERROR, error);
        this.handleServerError(error);
        throw error;
      });
  }
}

export default NotificationsStore;
