import { action, makeObservable, observable, runInAction } from 'mobx';
import ApiStore from '@stores/ApiStore';
import { ERROR, FETCHED, FETCHING } from '@helpers/constants';
import { timeSlots as timeSlotsEndpoint } from '@helpers/endpoints';

class TimeSlotsStore extends ApiStore {
  constructor(errorsStore, rootStore) {
    super(errorsStore, rootStore);
    this.initFields();
    makeObservable(this, {
      data: observable,
      timeSlots: observable,
      fetchTimeSlots: action,
      setTimeSlots: action
    });
    this.errorsStore = errorsStore;
    this.rootStore = rootStore;
  }

  initFields() {
    runInAction(() => {
      this.timeSlots = [];
      this.currentDate = '';
      this.divisionId = '';
      this.data = new Map();
    });
  }

  setTimeSlots(newTimeSlots) {
    this.timeSlots = newTimeSlots;
  }

  clearTimeSlots() {
    this.setTimeSlots([]);
  }

  fetchTimeSlots(dateString, divisionId) {
    this.changedState(FETCHING);
    this.currentDate = dateString;
    this.divisionId = divisionId;
    return timeSlotsEndpoint.forDate(dateString, divisionId)
      .then((response) => {
        this.setTimeSlots(this.getDataFromResponse(response));
        this.changedState(FETCHED);
      })
      .catch((error) => {
        this.changedState(ERROR, error);
        this.handleServerError(error);
        throw error;
      });
  }
}

export default TimeSlotsStore;
