const ButtonTheme = (theme) => ({
  overrides: {
    MuiButton: {
      root: {
        height: 48,
        borderRadius: '0',
        textTransform: 'none'
      },
      contained: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: `${theme.palette.primary.main} !important`
        },
        '&$disabled': {
          backgroundColor: '#C7C7C7',
          color: theme.palette.common.white
        }
      },
      containedSecondary: { color: theme.palette.common.white },
      outlined: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
        '&:hover': {
          border: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white
        }
      },
      text: {
        fontWeight: 600,
        height: 'auto'
      }
    }
  }
});

export default ButtonTheme;
