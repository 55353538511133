import { userStore } from '@stores/stores';

function validateTokenAndMakeAction(onSuccess, onError) {
  if (userStore.hasTokenInLocalStorage()) {
    if (userStore.notValidated) {
      userStore.validateToken()
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          userStore.removeToken();
          onError();
        });
    }
  } else {
    onError();
  }
}

export {
  validateTokenAndMakeAction,
};
