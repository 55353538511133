import React, { Component } from 'react';
import Typography from '@components/atoms/Typography';
import { Trans } from 'react-i18next';
import { LoginHeaderContainer } from './styles';

class LoginHeader extends Component {
  render() {
    return (
      <LoginHeaderContainer>
        <Typography variant="h1" gutterBottom data-cy="loginScreenTitle">
          <Trans i18nKey="loginHeader:title">{{ accountName: this.props.accountName }}</Trans>
        </Typography>
      </LoginHeaderContainer>
    );
  }
}

export default LoginHeader;
