import { addZeroBefore } from '@helpers/formatting/formatting';

function extractSecondsFromMilliseconds(microSeconds) {
  return Math.floor((microSeconds % (1000 * 60)) / 1000);
}

function extractMinutesFromMilliseconds(milliSeconds) {
  return Math.floor((milliSeconds % (1000 * 60 * 60)) / (1000 * 60));
}

function extractHoursFromMilliseconds(milliSeconds) {
  return Math.floor((milliSeconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
}

function minutesToMilliseconds(minutes) {
  return minutes * 60 * 1000;
}

function millisecondsToMinutes(milliseconds) {
  return Math.round(milliseconds / 1000 / 60);
}

function floorToFifteenMinutes(milliseconds) {
  const fifteenMinutes = 900000;
  const times = Math.floor(milliseconds / fifteenMinutes);
  return times * fifteenMinutes;
}

function secondsToMilliseconds(seconds) {
  return seconds * 1000;
}

function hoursToMilliseconds(hours) {
  return hours * 3600000;
}

function timeToMilliseconds(hours, minutes, seconds) {
  return hoursToMilliseconds(hours) + minutesToMilliseconds(minutes)
    + secondsToMilliseconds(seconds);
}

function getMinutesBetweenHours(startHour, startMinutes, endHour, endMinutes) {
  let date = '2020/10/09';
  const startTime = new Date(`${date} ${addZeroBefore(startHour)}:${addZeroBefore(startMinutes)}`);
  if (startHour === 23 && endHour === 0) date = '2020/10/10';
  const endTime = new Date(`${date} ${addZeroBefore(endHour)}:${addZeroBefore(endMinutes)}`);
  const difference = endTime.getTime() - startTime.getTime();
  return Math.round(difference / 60000);
}

function addSeconds(date, secondsToAdd) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds() + secondsToAdd,
    date.getMilliseconds(),
  );
}

export {
  extractSecondsFromMilliseconds,
  extractMinutesFromMilliseconds,
  extractHoursFromMilliseconds,
  minutesToMilliseconds,
  millisecondsToMinutes,
  floorToFifteenMinutes,
  secondsToMilliseconds,
  hoursToMilliseconds,
  timeToMilliseconds,
  getMinutesBetweenHours,
  addSeconds,
};
