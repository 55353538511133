import React from 'react';
import { Route } from 'react-router-dom';
import AuthorizedScreenWrapper from '@helpers/authorization/AuthorizedScreenWrapper';
import NotAuthorizedScreenWrapper from '@helpers/authorization/NotAuthorizedScreenWrapper';
import { AUTHORIZED_ROUTE, NOT_AUTHORIZED_ROUTE } from './constants';

function getAuthorizedRouteComponent({
  path, exactPath, children, key,
}) {
  return (
    <Route exact={exactPath} path={path} key={key}>
      <AuthorizedScreenWrapper>{children}</AuthorizedScreenWrapper>
    </Route>
  );
}

function getNotAuthorizedRouteComponent({
  path, exactPath, children, key,
}) {
  return (
    <Route exact={exactPath} path={path} key={key}>
      <NotAuthorizedScreenWrapper>{children}</NotAuthorizedScreenWrapper>
    </Route>
  );
}

function renderRoutes(routesDefinitions) {
  const routesForRender = [];
  routesDefinitions.forEach((routeDefinition, key) => {
    let routeForRender;
    switch (routeDefinition.kind) {
      case AUTHORIZED_ROUTE:
        routeForRender = getAuthorizedRouteComponent({
          ...routeDefinition,
          key,
        });
        break;
      case NOT_AUTHORIZED_ROUTE:
        routeForRender = getNotAuthorizedRouteComponent({
          ...routeDefinition,
          key,
        });
        break;
      default:
        routeForRender = getNormalRouteComponent({
          ...routeDefinition,
          key,
        });
    }
    routesForRender.push(routeForRender);
  });
  return routesForRender;
}

export {
  renderRoutes,
};
