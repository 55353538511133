import React, { Component } from 'react';
import PropTypes from 'prop-types';
import YourBookingsElement from '@components/molecules/YourBookingsElement';
import YourBookingsLegend from '@components/molecules/YourBookingsLegend';
import { Trans } from 'react-i18next';
import { stringToDate } from '@helpers/dates/dates';
import { addSeconds } from '@helpers/time/time';
import { BookingBox, BookingBoxContainer } from './styles';

class YourBookings extends Component {
  constructor(props) {
    super(props);
    this.renderBookings = this.renderBookings.bind(this);
    this.bookingsForYourBookingComponents = this.bookingsForYourBookingComponents.bind(this);
    this.state = { serializedBookings: [] };

    this.bookingsForYourBookingComponents();
  }

  bookingsForYourBookingComponents() {
    const result = [];
    this.props.bookings.map((booking) => {
      const startDateTime = stringToDate(booking.attributes.startedAt);
      const endDateTime = addSeconds(stringToDate(booking.attributes.endedAt), 1);
      result.push({
        id: booking.id,
        startHour: startDateTime.getHours(),
        startMinutes: startDateTime.getMinutes(),
        endHour: endDateTime.getHours(),
        endMinutes: endDateTime.getMinutes(),
        divisionName: booking.attributes.divisionName,
        date: stringToDate(booking.attributes.date),
        spotsAmount: booking.attributes.spotsAmount,
      });
    });
    this.state.serializedBookings = result;
  }

  renderBookings() {
    const { onCancelBookingClick } = this.props;
    if (this.state.serializedBookings.length === 0) {
      return (
        <p data-cy="zeroBookingsTitle">
          <Trans>yourBookings:zeroBookingsTitle</Trans>
        </p>
      );
    }
    return (
      <>
        {this.state.serializedBookings.map((booking, i) => (
          <YourBookingsElement
            {...booking}
            key={i}
            onCancelClick={() => onCancelBookingClick(booking)}
          />
        ))}
      </>
    );
  }

  render() {
    return (
      <BookingBoxContainer>
        <BookingBox>
          {this.renderBookings()}
        </BookingBox>
        <YourBookingsLegend />
      </BookingBoxContainer>
    );
  }
}

YourBookings.propTypes = {
  bookings: PropTypes.array.isRequired,
  onCancelBookingClick: PropTypes.func.isRequired,
};

export default YourBookings;
