import commonTranslations from '@helpers/commonTranslations/de/common.json';
import atomsDeTranslations from '@components/atoms/translations/de/index';
import moleculesDeTranslations from '@components/molecules/translations/de/index';
import organismsDeTranslations from '@components/organisms/translations/de/index';
import screensDeTranslations from '@components/screens/translations/de/index';

const deTranslations = {
  common: commonTranslations,
  ...atomsDeTranslations,
  ...moleculesDeTranslations,
  ...organismsDeTranslations,
  ...screensDeTranslations,
};

export default deTranslations;
