import styled from 'styled-components';
import { rgba } from 'polished';
import { withTheme } from '@material-ui/core';

export const StyledSlot = styled.div`
  box-sizing: border-box;
  flex: 1;
  height: ${({ height }) => height}px;
  position: relative;
  z-index: 2;
  padding: 1px 0;
  cursor: ${({ booked, isOccupied, bookable, cantBookMore }) => {
    if (booked) return 'default';
    if (cantBookMore()) return 'default';
    if (isOccupied()) return 'pointer';
    if (bookable) return 'pointer';
    return 'pointer';
  }};
`;

export const StyledSlotContent = withTheme(styled('div')`
  z-index: 2;
  padding: 0 5px;
  height: 100%;
  background-color: ${({ booked, isOccupied, bookable, cantBookMore, theme }) => {
    if (booked) return theme.palette.accent;
    if (cantBookMore()) return theme.palette.primary.main;
    if (isOccupied()) return theme.palette.primary.main;
    if (bookable) return `${rgba(theme.palette.grey[200], 0.8)}`;
    return `${theme.palette.primary.main}`;
  }};
  color: ${({ booked, isOccupied, bookable, cantBookMore, theme }) => {
    if (booked) return `${theme.palette.common.white}`;
    if (cantBookMore()) return `${theme.palette.common.white}`;
    if (isOccupied()) return `${theme.palette.common.white}`;
    if (bookable) return `${theme.palette.grey['600']}`;
    return `${theme.palette.common.white}`;
  }};
`);

export const StyledTime = styled.span`
  width: 88px;
  text-align: right;
`;
