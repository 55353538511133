import bookingScreenTranslations from '@components/screens/BookingScreen/translations/pl.json';
import loginScreenTranslations from '@components/screens/LoginScreen/translations/pl.json';
import resetPasswordScreenTranslations from '@components/screens/ResetPasswordScreen/translations/pl.json';
import newPasswordScreenTranslations from '@components/screens/NewPasswordScreen/translations/pl.json';
import confirmCodeScreenTranslations
  from '@components/screens/ConfirmCodeScreen/translations/pl.json';

const screensPlTranslations = {
  bookingScreen: bookingScreenTranslations,
  loginScreen: loginScreenTranslations,
  confirmCodeScreen: confirmCodeScreenTranslations,
  resetPasswordScreen: resetPasswordScreenTranslations,
  newPasswordScreen: newPasswordScreenTranslations
};

export default screensPlTranslations;
