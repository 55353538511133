import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@components/atoms/Typography';
import Form from '@components/organisms/Form';
import TextField from '@components/atoms/TextField';
import Loader from '@components/atoms/Loader';
import { navigateTo } from '@helpers/navigation/navigation';
import { BOOKING_URL } from '@helpers/urls';
import logo from '@assets/logo.png';
import { Link } from 'react-router-dom';
import Refresh from '@material-ui/icons/Refresh';
import Div100vh from 'react-div-100vh';
import { Trans, withTranslation } from 'react-i18next';
import {
  LoginBody,
  LoginContainer,
  LoginFooter,
  LoginFormContainer,
  LoginStep,
  LoginSteps,
  LoginTextFields,
  LoginTopBar,
  StyledButton,
} from './styles';

class ConfirmCodeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      authorizing: false,
    };
    this.handleCodeVerification = this.handleCodeVerification.bind(this);
  }

  handleCodeVerification(fieldsValues) {
    this.props.userStore.verifyCode({
      email: this.props.userStore.email,
      code: fieldsValues.code.value,
    })
      .then(() => {
        navigateTo([BOOKING_URL]);
      })
      .catch(() => {
      });
  }

  renderConfirmCodeForm() {
    return (
      <Form errors={this.props.userStore.error}>
        <LoginTextFields>
          <TextField
            data-cy="codeTextField"
            name="TextField"
            key="code"
            label={this.props.t('confirmCodeScreen:codeFieldLabel')}
            margin="normal"
          />
        </LoginTextFields>
        <StyledButton
          data-cy="confirmCodeButton"
          name="Button"
          loading={this.props.userStore.verifyingCode}
          variant="contained"
          fullWidth
          onClick={this.handleCodeVerification}
          key="accept"
        >
          <Trans>confirmCodeScreen:confirmButtonTitle</Trans>
        </StyledButton>
      </Form>
    );
  }

  renderContent() {
    if (this.state.authorizing) {
      return <Loader />;
    }
    return (
      <>
        <Typography data-cy="confirmCodeScreenTitle" variant="h1" gutterBottom>
          <Trans>
            confirmCodeScreen:title
          </Trans>
        </Typography>
        <Typography data-cy="confirmCodeScreenSubTitle" variant="body1">
          <Trans>
            confirmCodeScreen:subTitle
          </Trans>
        </Typography>
      </>
    );
  }

  renderFooter() {
    return (
      <LoginFooter>
        <Link to="">
          <Box display="flex" alignItems="center">
            <Refresh />
            <Trans>confirmCodeScreen:sendLinkButtonTitle</Trans>
          </Box>
        </Link>
      </LoginFooter>
    );
  }

  renderBody() {
    return (
      <LoginBody>
        <Grid item xs={12}>
          <LoginTopBar>
            <img src={logo} alt="Frantz" width="48px" height="47px" />
            <LoginSteps>
              <LoginStep isActive />
              <LoginStep isActive />
            </LoginSteps>
          </LoginTopBar>
        </Grid>
        <Grid item xs={12} lg={7}>
          {this.renderContent()}
          <LoginFormContainer>
            {this.renderConfirmCodeForm()}
          </LoginFormContainer>
        </Grid>
      </LoginBody>
    );
  }

  render() {
    return (
      <>
        <CssBaseline />
        <Div100vh>
          <LoginContainer>
            {this.renderBody()}
            {this.renderFooter()}
            <Box py={2}>
              <Typography variant="body1" data-cy="infiniteLoopC">© 2020 Infinite Loop</Typography>
            </Box>
          </LoginContainer>
        </Div100vh>
      </>
    );
  }
}

const confirmCodeScreenWithoutI18n = inject('userStore', 'errorsStore')(
  observer(ConfirmCodeScreen),
);
export { confirmCodeScreenWithoutI18n };
export default withTranslation()(confirmCodeScreenWithoutI18n);
