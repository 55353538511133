import React, { Component } from 'react';
import './App.css';
import { renderRoutes } from '@helpers/routesHelpers';
import { Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getTheme } from '@helpers/theme.jsx';
import routesDefinitions from './routes';

class App extends Component {
  get theme() {
    return createMuiTheme(getTheme(this.props.colorThemeStore.currentColorTheme));
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        <Switch>
          {renderRoutes(routesDefinitions)}
        </Switch>
      </MuiThemeProvider>
    );
  }
}

export default inject('userStore', 'accountStore', 'colorThemeStore')(observer(App));
