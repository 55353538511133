import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BookingBox } from '@components/molecules/YourBookingsElement/styles';
import CancelBookingButton from '@components/atoms/buttons/CancelBookingButton';
import Typography from '@components/atoms/Typography';
import { getSlotFormattedDateTime } from '@helpers/dates/dates';
import { Trans, withTranslation } from 'react-i18next';

class YourBookingsElement extends Component {
  render() {
    const {
      date,
      startHour,
      startMinutes,
      endHour,
      endMinutes,
      onCancelClick,
      divisionName,
      spotsAmount
    } = this.props;

    return (
      <BookingBox data-cy='yourBookingsElement'>
        <Typography variant='body1'>
          <b>{getSlotFormattedDateTime(date, startHour, startMinutes, endHour, endMinutes)}</b>
        </Typography>
        {divisionName}
        {spotsAmount !== 1
        && (
        <Typography variant='body2'>
          <b><Trans values={{ spotsAmount }}>yourBookingsElement:reservedSpotsAmount</Trans></b>
        </Typography>
        )}
        <CancelBookingButton onCancelClick={onCancelClick} />
      </BookingBox>
    );
  }
}

YourBookingsElement.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  startHour: PropTypes.number.isRequired,
  startMinutes: PropTypes.number.isRequired,
  endHour: PropTypes.number.isRequired,
  endMinutes: PropTypes.number.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default withTranslation()(YourBookingsElement);
