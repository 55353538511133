import { stringToDate } from './dates/dates';

function getUrlParam(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

function hasUrlParam(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(paramName);
}

/**
 * Returns date from url param as Date object
 * @returns {*|Date}
 */
function getDateFromUrl() {
  return stringToDate(getUrlParam('date'));
}

export { getUrlParam, hasUrlParam, getDateFromUrl };
