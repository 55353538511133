import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const BookingBox = styled.div`
  margin: 16px 0;
`;

export const BookingBoxContainer = withTheme(styled('div')`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: column;
  }
`);
